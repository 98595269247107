import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ConfigurationService, GoogleAnalyticsService, OptimizelyService } from '@central/ng-shared';
import { CookieService } from 'ngx-cookie-service';
import { AmpOpApiService } from '../amp-op-api/amp-op-api.service';
import { AmpLocalStorageService } from '../amp-local-storage-service';
import { AmpDomainComponent } from '../amp-domain/amp-domain.component';
import { NetworkValidationService } from '../../shared/network-validation/network-validation.service';
import { StepperStep } from '../amp-stepper/amp-stepper.interface';
import { Observable } from 'rxjs';

@Component({
	selector: 'amp-checkout',
	templateUrl: 'checkout.component.html',
	styleUrls: ['checkout.component.scss'],
})
export class CheckoutComponent implements OnInit, AfterViewInit {
	@ViewChild('payment', { static: true }) public payment: any;
	@ViewChild('stepper') public stepper: any;
	@ViewChild(AmpDomainComponent, {static: true}) public ampDomain: AmpDomainComponent;
	public status = 'loading';
	public cartStatus = 'pending';
	public paymentStatus = 'pending';
	public savedCartLoaded = false;
	public iridCookie: string;
	public irmpCookie: string;
	public ampConnectCookie: string;
	public ampTerms = [];
	public domainsInfo = {};
	public domainOnly = false;
	public domainPkg = 39;
	public domainProductId = 10973;
	public domainOnlyInfo = {};
	public domainName = '';
	public domain = {};
	public initialAdvance = true;
	public accountInfo = {};
	public hideCart = true;
	public freeDomain = false;
	public requiresDomain = false;
	public hideDomainSelect = true;
	public hideOffers = true;
	public offers = [];
	public hasConfiguration = false;
	public hideConfiguration = true;
	public ppHost = '';
	public opHost = '';
	public chatUrl = '';
	public emptyCartUrl = 'https://www.inmotionhosting.com';
	public failureRedirectUrl = 'https://www.inmotionhosting.com';
	public steps: StepperStep[] = [];
	public initialStep = 1;
	public hostingStep: StepperStep = { step: 1, title: 'HOSTING', isEditable: false, completed: true, disabled:true, selected: false}
	public domainStep: StepperStep = { step: 2, title: 'DOMAIN', isEditable: true, completed: false, disabled:false, selected: false}
	public offerStep: StepperStep = { step: 2, title: 'ADDONS', isEditable: false, completed: false, disabled:false, selected: false}
	public configStep: StepperStep = { step: 3, title: 'CONFIGURE', isEditable: false, completed: false, disabled:false, selected: false}
	public completionStep: StepperStep = { step: 3, title: 'COMPLETE', isEditable: false, completed: false, disabled:false, selected: false}
	public availableAddons = [];
	public publicNetwork = true;
	public packages = [];
	public stepPlaceHolder: StepperStep = { step: 0, title: '', isEditable: false, completed: false, disabled:false, selected: false}
	public upsellOfferAdded = false;

	public progressMessages = [
		{ msg: 'Checking cart info', time: 2000, type: 'any'},
		{ msg: 'Loading cart details', time: 4000, type: 'any'},
		{ msg: 'Finishing up...', time: 0, type: 'any'},
	];

	public constructor(
		public configService: ConfigurationService,
		public ampOpApiService: AmpOpApiService,
		public localStorage: AmpLocalStorageService,
		private route: ActivatedRoute,
		private router: Router,
		private optimizelyService: OptimizelyService,
		private cookieService: CookieService,
		private cdr :ChangeDetectorRef,
		public googleAnalytics: GoogleAnalyticsService,
		private location: Location,
		public readonly networkValidation: NetworkValidationService,
	) {
		this.ppHost = this.configService.config.powerPanel.ampHost;
		this.opHost = this.configService.config.powerPanel.opHost;
		this.chatUrl = this.ppHost + this.configService.config.powerPanel.chatUrl;
		this.emptyCartUrl = this.configService.config.powerPanel.emptyCartUrl;
		this.steps = [this.hostingStep, this.domainStep];
		if(this.cookieService.check('irid')) {
			this.iridCookie = this.cookieService.get('irid');
		}
		if(this.cookieService.check('irmp')) {
			this.irmpCookie = this.cookieService.get('irmp');
		}
		this.networkValidation.publicNetwork$.subscribe(publicNetwork => {
			this.publicNetwork = publicNetwork;
		});
	}

	public ngOnInit() {
		const packageString = this.route.snapshot.params['products'];
		const cskParam = this.route.snapshot.params['csk'];
		const domainPkg = this.route.snapshot.params['domainPkg'];
		const allowType = this.route.snapshot.queryParams['ampallowedtypes'];
		const upsellAllowed = this.route.snapshot.queryParams['ampupsellallowed'];
		const googleAuthAllowed = this.route.snapshot.queryParams['ampgoogleauthallowed'];
		const campaign = this.route.snapshot.queryParams['campaign'];
		const affiliates = this.route.snapshot.queryParams['affiliates'];
		const referrer = window.document.referrer;
		if(affiliates || campaign || referrer) {
			this.ampOpApiService.setOrderInfo(affiliates, campaign, referrer);
		}
		if(allowType !== undefined) {
			this.ampOpApiService.setAllowedType(allowType);
		}
		if(upsellAllowed !== undefined) {
			this.ampOpApiService.setAmpUpsellAllowed(true);
		}
		if(googleAuthAllowed !== undefined) {
			this.ampOpApiService.setAmpGoogleAuthAllowed(true);
		}
		this.ampOpApiService.clearCatalogInfo()
		if(packageString) {
			this.handlePackages(packageString);
		} else if(domainPkg) {
			this.domainPkg = domainPkg;
			const domainSearch = this.route.snapshot.queryParams['search'];
			this.handleDomainOnly(domainSearch);
		} else {
			this.handleCsk(cskParam)
		}
	}

	public ngAfterViewInit() {
		this.cdr.detectChanges();
	}

	/**
	 * Handles the domain-only checkout flow by configuring steps and initializing domain search
	 * @param domainPkg - The domain package identifier
	 * @param domainSearch - The domain search query string
	 * @param loadFromCart - Flag indicating whether to load from existing cart (default: false)
	 */
	public handleDomainOnly(domainSearch, loadFromCart = false) {
		this.domainOnly = true;
		this.setDomainOnlySteps()
		if(loadFromCart === false) {
			this.ampOpApiService.clearCartInfo();
			const packageInfo = this.ampOpApiService.parsePackageInfo(this.domainPkg +'-'+this.domainProductId);
			this.ampOpApiService.createCart(packageInfo).subscribe(
				(cart) => {
					if(cart) {
						this.ampDomain.cartRefId = cart['RefId'];
						this.ampDomain.cartId = cart['Id'];
						this.cartStatus = 'success';
						this.handleDomainOnlySearch(domainSearch);
					}
				},
				(error) => {
					console.log(error)
					//this.status = 'failed';
					//this.failureRedirect();
				}
			)
		} else {
			this.handleDomainOnlySearch(domainSearch);
		}
		
		
	}

	public handleDomainOnlySearch(domainSearch) {
		this.ampDomain.handleDomainOnlySearch(domainSearch);
		history.pushState(null,null,'/amp/checkout#domain')
		this.status = 'showcart';
		this.hideCart = true;
		this.hideDomainSelect = false;
	}

	/**
	 * Handles the Customer Session Key (CSK) processing for checkout flow
	 * Retrieves cart information and configures the checkout based on the CSK
	 * 
	 * @param cskParam - The Customer Session Key parameter from the route
	 * @description
	 * - If CSK exists in stored cart, uses that
	 * - If CSK provided as parameter, clears stored info and handles marketing params
	 * - Retrieves catalog info from cart and either:
	 *   - Redirects to old cart if needed
	 *   - Handles domain-only flow
	 *   - Loads regular cart
	 * - Falls back to failure redirect if no valid CSK found
	 */
	public handleCsk(cskParam) {
		let csk;
		let orderSession = false;
		const cartInfo = this.ampOpApiService.getStoredCart();
		if(cartInfo.hasOwnProperty('CustomerSessionKey')) {
			csk = cartInfo.CustomerSessionKey;
		}
		if(cskParam) {
			csk = cskParam;
			orderSession = true;
			this.ampOpApiService.clearStoredInfo();
			const mktgp = this.route.snapshot.queryParams['mktgp'];
			// Set marketing cookie if params supplied
			if(mktgp === "t") {
				const mktgpCookieData = {};
				mktgpCookieData["utm_campaign"] = (this.route.snapshot.queryParams['utm_campaign'] ? this.route.snapshot.queryParams['utm_campaign'] : "");
				mktgpCookieData["utm_medium"] = (this.route.snapshot.queryParams['utm_medium'] ? this.route.snapshot.queryParams['utm_medium'] : "");
				mktgpCookieData["utm_source"] = (this.route.snapshot.queryParams['utm_source'] ? this.route.snapshot.queryParams['utm_source'] : "");
				mktgpCookieData["utm_term"] = (this.route.snapshot.queryParams['utm_term'] ? this.route.snapshot.queryParams['utm_term'] : "");
				mktgpCookieData["p_type"] =(this.route.snapshot.queryParams['p_type'] ? this.route.snapshot.queryParams['p_type'] : "");
				mktgpCookieData["p_prod"] = (this.route.snapshot.queryParams['p_prod'] ? this.route.snapshot.queryParams['p_prod'] : "");
				mktgpCookieData["p_ev"] = (this.route.snapshot.queryParams['p_ev'] ? this.route.snapshot.queryParams['p_ev'] : "");
				this.ampOpApiService.setCookieData('mktgp',JSON.stringify(mktgpCookieData), 90);
				const affiliates = this.route.snapshot.queryParams['affiliates'];
				if(affiliates) {
					this.ampOpApiService.setCookieData('irid',affiliates, 90);
				}
			}
		}
		if(csk) {
			this.ampOpApiService.getOPCatalogFromCartInfo(csk, orderSession)
					.subscribe(
					(data) => {
						if(data.hasOwnProperty('redirect') && data['redirect'] === true) {
							this.redirectToOldOpCart(csk)
						} else {
							if(data.hasOwnProperty('packages')
								&& (data['packages'].hasOwnProperty('hasDomain') && data['packages'].hasOwnProperty('hasHosting'))
								&& data['packages']['hasDomain'] === true && data['packages']['hasHosting'] === false) {
								const domainSearchInfo = data['packages']['purchasedDomain'];
								this.handleDomainOnly(domainSearchInfo, true);
								if(domainSearchInfo.trim() !== '') {
									this.ampDomain.checkingSavedDomainAvailability = true;
								}

							} else {
								if(this.route.snapshot.fragment !== 'domain') {
									history.pushState(null,null,'/amp/checkout#domain')
								}
								this.loadCart(data)
							}
							
						}
					})
		} else {
			this.status = 'failed';
			this.failureRedirect();
		}
	}

	/**
	 * Handles the failure scenario in the checkout process by clearing stored information
	 * and redirecting after a delay
	 * 
	 * @description
	 * - Sets the status to 'failed'
	 * - Clears any stored information via ampOpApiService
	 * - Currently has a commented redirect to inmotionhosting.com after 3 seconds
	 */
	public failureRedirect() {
		this.status = 'failed';
		this.ampOpApiService.clearStoredInfo();
		setTimeout( () => {
			window.location.href = this.failureRedirectUrl;
		}, 3000 );
	}

	/**
	 * Handles the redirection to the old OP cart
	 * 
	 * @param csk - The Customer Session Key
	 * @param packageInfo - Optional package information for creating a new cart
	 * @description
	 * - Sets the status to 'redirect_to_old_cart'
	 * - If packageInfo is provided, creates a new cart and redirects to the old OP cart
	 * - Otherwise, redirects to the old OP cart directly
	 */
	public redirectToOldOpCart(csk, packageInfo = null) {
		this.status = 'redirect_to_old_cart';
		if(packageInfo !== null) {
			this.ampOpApiService.createCart(packageInfo).subscribe(
				(cart) => {
					let newUrl = this.failureRedirectUrl;
					if(cart && cart.hasOwnProperty('CustomerSessionKey')) {
						this.ampOpApiService.clearStoredInfo();
						newUrl = this.opHost + '/order-process#order-session/' + cart['CustomerSessionKey'];
					}
					setTimeout( () => {
						window.location.href = newUrl;
					}, 1000 );
				}
			)
		} else {
			this.ampOpApiService.clearStoredInfo();
			setTimeout( () => {
				window.location.href = this.opHost + '/order-process#order-session/' + csk;
			}, 1000 );
		}
	}

	/**
	 * Handles the packages processing for checkout flow
	 * 
	 * @param packageString - The package string from the route
	 * @description
	 * - Retrieves campaign, affiliates, and referrer from query params
	 * - Sets order info via ampOpApiService
	 * - Pushes state to '/amp/checkout#domain'
	 * - Parses package info from package string
	 * - Fetches catalog info from OP API
	 * - Handles redirection to old OP cart if necessary
	 * - Loads cart data or redirects to old cart
	 */
	public handlePackages(packageString, upsellOffer = false) {
		if(upsellOffer === false) {
			history.pushState(null,null,'/amp/checkout#domain')
		}
		const packageInfo = this.ampOpApiService.parsePackageInfo(packageString);
		this.ampOpApiService.getOPCatalogFromPackageString(packageInfo)
			.subscribe(
			(data) => {
				if(data.hasOwnProperty('redirect') && data['redirect'] === true) {
					this.redirectToOldOpCart(null,packageInfo);
				} else {
					this.loadCart(data, upsellOffer)
				}
			})
	}

	/**
	 * Loads the cart data and sets up the checkout
	 * 
	 * @param data - The cart data from the OP API
	 * @description
	 * - Checks if cart data is available and valid
	 * - Sets up the payment and ampDomain components
	 * - Handles the cart loading or creation
	 */
	public loadCart(data, upsellOffer = false) {
		if(data.hasOwnProperty('packages') && data['packages'].hasOwnProperty('packageFound')) {
			if(data['packages']['packageFound'] === true) {
				this.payment.useCartService = false
				this.packages = [...data['packages']['packages']];
				this.payment.packages = [...data['packages']['packages']];
				this.payment.term = data['packages']['term'];
				this.ampTerms = this.sortAmpTerms(data['packages']['terms']);
				this.ampDomain.freeDomain = data['packages']['freeDomain'];
				this.freeDomain = data['packages']['freeDomain'];
				this.requiresDomain = data['packages']['requiresDomain'];
				this.domainOnly = (data['packages'].hasOwnProperty('hasDomain') && data['packages'].hasOwnProperty('hasHosting'))
					&& data['packages']['hasDomain'] === true && data['packages']['hasHosting'] === false;
				if(this.requiresDomain) {
					this.hideDomainSelect = false;
				}
				if(upsellOffer === true) {
					this.removeOffers();
				}
				const offerType = this.domainOnly ? 'domain' : 'hosting';
				let offerProductId = null;
				if(offerType === 'hosting') {
					offerProductId = this.packages.find(e => e.type === 'hosting').productId;
				}
				this.handleOffers(offerType, offerProductId).subscribe(
					() => {
						if(this.setConfigs()) {
							this.steps.push(this.configStep)
						}
		
						this.completionStep.step = this.steps.length + 1;
						this.steps.push(this.completionStep)
						
						
						this.status = 'showcart';
						if(upsellOffer === true && this.ampDomain.selectedDomainInfo.hasOwnProperty('id')) {
							this.domainOnly = false;
							this.ampDomain.domainOnly = false;
							this.domainSelected(this.ampDomain.selectedDomainInfo);
						} else {
							if(data.hasOwnProperty('cart')) {
								this.ampDomain.cartRefId = data['cart']['RefId'];
								this.ampDomain.cartId =  data['cart']['Id'];
								this.cartStatus = 'success';
								if(data['packages']['domainName'] !== null) {
									if(data['packages']['purchasedDomain']) {
										this.ampDomain.domainName =  data['packages']['purchasedDomain'];
										this.ampDomain.checkingSavedDomainAvailability = true;
										this.ampDomain.updateDomainAvailability(true);
									} else if(data['packages']['domainName'].includes('.temporary.link')) {
										this.ampDomain.setDomainSelection(true);
									} else {
										this.ampDomain.domainSelectionType = 'existing';
										this.ampDomain.handleDomainType();
										this.ampDomain.domainName =  data['packages']['domainName'];
										this.ampDomain.domainChange({});
										this.ampDomain.setDomainSelection(false);
									}
									
								}
								
							} else {
								this.ampOpApiService.createCart(data['packageInfo']).subscribe(
									(cart) => {
										if(cart) {
											this.ampDomain.cartRefId = cart['RefId'];
											this.ampDomain.cartId = cart['Id'];
											this.cartStatus = 'success';
										}
									},
									(error) => {
										console.log(error)
										this.status = 'failed';
										this.failureRedirect();
									}
								)
							}
						}
					}
				);

				

			} else {
				this.status = 'failed';
			}

		} else {
			this.status = 'failed';
		}
		if(this.status === 'failed') {
			this.failureRedirect();
		}
	}

	/**
	 * Sets up the configurations for the checkout
	 * 
	 * @param update - Flag to indicate if the configuration should be updated
	 * @returns True if configurations are set, false otherwise
	 */
	public setConfigs(update = true) {
		const configPackages = this.payment.packages.filter(e => e.hasOwnProperty('configs'))
		const result = configPackages.length > 0;
		if(result) {
			this.payment.ampConfig.packages = this.payment.packages;
			this.payment.ampConfig.setup(update);
			this.hasConfiguration = true;
		}
		return result;
	}

	public setDomainInfo(domainInfo) {
		this.domainsInfo = domainInfo;
		const type = this.domainOnly ? 'domain' : 'hosting';
		const id = null;
		// placeholder for hosting
		/* if(type === 'hosting') {
			console.log('hosting',this.packages)
		} */
		this.handleOffers(type, id).subscribe();
	}

	/**
	 * Processes and adds a domain product to the checkout packages
	 * 
	 * @param domain - The domain object containing domain information including:
	 *   - id: The product offering ID
	 *   - price: The domain price
	 *   - name: The domain name
	 *   - packageId: The package identifier
	 *   - info: Object containing domain eligibility info (freeEligible, privacyEligible)
	 * @param hosting - The hosting package object (optional) used to check free domain eligibility
	 * @param autoaddon - Boolean flag to automatically add privacy addon if eligible (default: true)
	 * 
	 * @description
	 * This method:
	 * - Finds and processes the domain product from domainsInfo
	 * - Handles free domain eligibility if hosting package allows it
	 * - Creates catalog package for the domain
	 * - Adds privacy protection addon if eligible and autoaddon is true
	 * - Updates payment packages and domain information
	 */
	public handleDomainProduct(domain, hosting = null, autoaddon = true) {
		if(domain.hasOwnProperty('price')) {

			let domainProduct = this.domainsInfo['data'].find(product => product.PackageToProductOfferingId === domain.id);
			if(domainProduct) {
				domainProduct.PackageToProductOfferingId = domain.id;
				domainProduct.Price = domain.price;
				domainProduct.DiscountPrice = domain.price;
				if(domain.info.freeEligible === true && hosting && hosting.info.FreeDomain === true) {
					this.freeDomain = true;
					this.ampDomain.freeDomain = true;
					this.payment.ampDomain.freeDomain = true;
					domainProduct.DiscountPrice = "0.00";
				} else {
					this.freeDomain = false;
					this.ampDomain.freeDomain = false;
					this.payment.ampDomain.freeDomain = false;
				}
				domainProduct = this.ampOpApiService.createCatalogPackage(domainProduct, domain.name);
				domainProduct.freeEligible = domain.info.freeEligible;
				domainProduct.planCode = domain.packageId + '_' + domain.id;
				domainProduct.code = domain.packageId + '_' + domain.id;
				domainProduct['options']['price'] = domain.price * 100;
				let privacyAddon = null;
				if(domain.info.privacyEligible === true) {
					domainProduct['addons'].forEach(v => {
						if(v.AddonAutoSelected) {
							const addon = this.ampOpApiService.createCatalogPackage(v, domain.name);
							if(this.ampDomain.domainPrivacy === true) {
								addon['selected'] = true;
								privacyAddon = addon;
							}
							
						}
					})
				} else {
					domainProduct.addons = [];
				}
				this.payment.packages.push(domainProduct);
				if(privacyAddon && autoaddon) {
					this.payment.packages.push(privacyAddon)
				}
			}
		}
		this.domainName = domain.name;
		this.payment.ampDomain = {...domain, freeDomain: this.freeDomain};
	}

	/**
	 * Handles the domain selection process for the checkout
	 * 
	 * @param domain - The domain object or string indicating the domain to be selected
	 * @param autoaddon - Boolean flag to automatically add privacy addon if eligible (default: true)
	 * 
	 * @description
	 * This method:
	 * - Handles the domain selection process
	 * - Resets the domain if 'reset domain' is passed
	 * - Updates the domain and hosting information
	 * - Handles the domain product addition
	 * - Sets up the configuration and cart loading
	 */
	public domainSelected(domain, autoaddon = true) {
		let advanceStepper = true;
		if(domain === 'reset domain') {
			domain = this.domain;
			this.initialAdvance = false;
			advanceStepper = false;
		} else {
			this.domain = domain;
		}
		let hosting = null;
		if(this.domainOnly === false) {
			const privIndx = this.payment.packages.findIndex(e => e.label === 'Domain Privacy');
			if(privIndx > -1) {
				this.payment.packages.splice(privIndx, 1);
			}
			const domainIndx = this.payment.packages.findIndex(e => e.type === 'domain');
			if(domainIndx > -1) {
				this.payment.packages.splice(domainIndx, 1);
			}
			hosting = this.payment.packages.find(e => e.type === 'hosting');
			this.ampDomain.freeDomain = hosting.info.FreeDomain;
		} else {
			this.ampDomain.freeDomain = false;
			this.payment.useCartService = false
			this.payment.packages = [];
		}
		this.handleDomainProduct(domain, hosting, autoaddon);
		if(this.domainOnly === false) {
			const hostingProduct = this.payment.packages.findIndex(product => product.type === 'hosting')
			if(hostingProduct !== -1) {
				this.payment.packages[hostingProduct]['options']['domain_name'] = this.domainName;
			}
			this.setConfigs();
		}
		if(this.payment.cart) {
			this.payment.cart.packages = this.payment.packages;
			this.payment.cart.fetchCatalog();
		}
		
		if(advanceStepper) {
			if(this.domainOnly === true) {
				this.stepper.selectedIndex = this.steps.findIndex(e => e.title.toLowerCase() === 'domain');
			}
			this.advanceStep();
		}

		if (this.configService.config['googleAnalytics'].routeOverrides) {
			const override = this.configService.config['googleAnalytics'].routeOverrides.find(
				item => this.location.path().startsWith(item.route)
			);
			this.googleAnalytics.trackConversion({
				send_to: override?.purchaseStart ?? ''
			});
		} else {
			this.googleAnalytics.trackConversion({
				send_to: this.configService.config.googleAnalytics.purchaseStart ?? ''
			});
		}
		if(window.hasOwnProperty('optimizely')) {
			this.optimizelyService.send('trackEvent', 'entered-cart');
		}
	}

	public advanceStep() {
		let newStepIdx = this.stepper.selectedIndex + 1;
		if(this.stepper.selectedIndex === this.steps.length - 1) {
			newStepIdx = this.steps.length - 1;
		}
		const newStep = this.steps[newStepIdx];
		
		if(newStep) {
			newStep.isEditable = true;
			this.stepper.advanceStep(newStep)
		} else {
			const domainStepIndx = this.steps.findIndex(e => e.title.toLowerCase() === 'domain');
			const stepIndx = this.steps.findIndex(e => e.title.toLowerCase() === this.stepPlaceHolder.title.toLowerCase());
			if(stepIndx > -1 && stepIndx < this.steps.length - 1) {
				this.stepper.advanceStep(this.steps[stepIndx + 1])
			} else if(domainStepIndx > -1) {
				this.stepper.handleStep(this.steps[domainStepIndx + 1])
			}
		}
	}

	public configComplete() {
		this.advanceStep();
	}

	public changedStep(data) {
		this.stepPlaceHolder = this.steps[this.stepper.selectedIndex];
		const url = '/amp/checkout#';
		const toStep = data.toStep;
		const fromStep = data.fromStep;
		fromStep.isEditable = fromStep.step < toStep.step
		if( toStep.title.toLowerCase() === 'domain') {
			if(this.domainName === this.ampDomain.tempDomainName) {
				this.ampDomain.domainName = '';
			} else if(this.ampDomain.domainName !== '' && this.ampDomain.domainSelectionType === 'new') {
				this.payment.packages = this.payment.packages.filter(e => e.type !== 'domain')
			}
			this.hideCart = true;
			this.hideDomainSelect = false;
		} else {
			this.hideDomainSelect = true;
		}
		if(toStep.title.toLowerCase() === 'addons') {
			this.hideOffers = false
		} else {
			this.hideOffers = true
		}
		if(toStep.title.toLowerCase() === 'configure') {
			this.hideConfiguration = false
		} else {
			this.hideConfiguration = true
		}
		if(toStep.title.toLowerCase() ===  'complete') {
			this.hideCart = false;
		} else {
			this.hideCart = true;
		}
		history.pushState(null,null, url + toStep.title.toLowerCase())
		this.ampOpApiService.addToEvents('cart:advance', {from: fromStep.title.toLowerCase(), to: (toStep.title.toLowerCase() === 'complete' ? 'billing' : toStep.title.toLowerCase()) , initial: this.initialAdvance});
	}

	public paymentBack() {
		this.stepper.previous();
	}

	public setDomainOnlySteps() {
		this.initialStep = 0;
		const newSteps = [];
		newSteps.push(this.domainStep)
		newSteps.push(this.completionStep)
		const domainIndx = newSteps.findIndex(e => e.title === 'DOMAIN');
		if(domainIndx > -1) {
			newSteps[domainIndx].step = 1
		}
		const completeIndx = newSteps.findIndex(e => e.title === 'COMPLETE');
		if(completeIndx > -1) {
			newSteps[completeIndx].step = 2
		}
		this.steps = newSteps;
	}

	public handleOffers(type = 'domain', id = null) {
		return new Observable(observer => {
			this.ampOpApiService.getUpsellOffers(type, id).subscribe(
				(offers) => {
					if(offers) {
						this.initialStep = 0;
						const newSteps = [];
						newSteps.push(this.domainStep)
						newSteps.push(this.offerStep)
						newSteps.push(this.completionStep)
						const domainIndx = newSteps.findIndex(e => e.title === 'DOMAIN');
						if(domainIndx > -1) {
							newSteps[domainIndx].step = 1
						}
						const offerIndx = newSteps.findIndex(e => e.title === 'ADDONS');
						if(offerIndx > -1) {
							newSteps[offerIndx].step = 2
							newSteps[offerIndx].isEditable = true;
							newSteps[offerIndx].completed = true;
						}
						const completeIndx = newSteps.findIndex(e => e.title === 'COMPLETE');
						if(completeIndx > -1) {
							newSteps[completeIndx].step = 3
						}
						this.steps = newSteps;
						if(this.stepper) {
							this.stepper.steps = newSteps;
						}
						this.offers = offers as any[];
						observer.next(null)
						observer.complete();
					} else {
						observer.next(null)
						observer.complete();
					}
				},
				(error) => {
					console.log('No offers:', error);
					observer.next(null)
					observer.complete();
				}
			)
		})
		
	}

	public goToPage(page) {
		const newStep = this.steps.find(e => e.title.toLowerCase() === page);
		if(newStep) {
			this.stepper.handleStep(newStep)
		}
	}

	public removeOffers() {
		this.initialStep = 1;
		const newSteps = [];
		newSteps.push(this.hostingStep)

		const domainIndx = this.steps.findIndex(e => e.title === 'DOMAIN');
		if(domainIndx > -1) {
			this.steps[domainIndx].step = 2
			newSteps.push(this.steps[domainIndx])
		}
		this.steps = newSteps;
		this.stepper.steps = newSteps;
		if(domainIndx > -1) {
		this.stepper.handleStep(this.steps[this.stepper.selectedIndex])
		}
		this.offers = [];
	}

	public removeCartItem(type) {
		if(type === 'domain') {
			const newStep = this.steps.find(e => e.title.toLowerCase() === 'domain');
			this.stepper.handleStep(newStep)
		}
		if(type === 'hosting') {
			this.stepPlaceHolder = this.steps[this.stepper.selectedIndex];
			this.domainOnly = true;
			this.ampDomain.domainOnly = true;
			this.freeDomain = false;
			this.ampDomain.freeDomain = false;
			this.payment.ampDomain.freeDomain = false;
			this.payment.dataCenters = [];
			this.payment.ampDomainOnly = true;
			this.setDomainOnlySteps();
			this.handleOffers('domain').subscribe(
				() => {
					this.ampTerms = [];
					this.payment.packages = this.payment.packages.filter(e => e.type === 'domain' || e.label === 'Domain Privacy');
					if(this.payment.packages.length > 0) {
						const domainPkgIndx = this.payment.packages.findIndex(e => e.type === 'domain');
						if(domainPkgIndx > -1) {
							if(this.payment.packages[domainPkgIndx].hasOwnProperty('code')) {
								this.payment.term = this.payment.packages[domainPkgIndx].code;
							}
							this.payment.packages[domainPkgIndx].freeEligible = false;
							delete this.payment.packages[domainPkgIndx].discounts;
		
						}
						if(this.payment.cart) {
							this.payment.cart.packages = this.payment.packages;
							this.payment.cart.fetchCatalog();
						}
					}
					const stepIndx = this.steps.findIndex(e => e.title.toLowerCase() === this.stepPlaceHolder.title.toLowerCase());
					if(stepIndx > -1) {
						this.stepper.advanceStep(this.steps[stepIndx])
					} else {
						this.advanceStep();
					}
				}
			)
			
		}
		if(type === 'empty') {
			this.ampOpApiService.emptyCart()
			this.ampOpApiService.clearStoredInfo();
			this.status = 'empty_cart';
			setTimeout( () => {
				window.location.href = this.emptyCartUrl;
			}, 100 );
		}
	}

	public paymentSuccess() {
		this.status = 'success';
		history.pushState(null,null,'/amp/checkout#purchase-success')
	}

	public termUpdated() {
		this.domainSelected('reset domain');
	}

	public upsellAmpOfferAdded(offer) {
		if(offer === null) {
			this.advanceStep();
		} else {
			this.upsellOfferAdded = true;
			this.stepPlaceHolder = this.steps[this.stepper.selectedIndex];
			const packageString = offer.PackageId + "-" + offer.PackageToProductOfferingId;
			this.handlePackages(packageString, true)
		}
	}

	public sortAmpTerms(terms) {
		if(terms) {
			terms.sort((a, b) =>
			{
				const aLength = a.plan_interval_unit.includes('year') ? a.plan_interval_length * 12 : a.plan_interval_length;
				const bLength = b.plan_interval_unit.includes('year') ? b.plan_interval_length * 12 : b.plan_interval_length;
				return aLength < bLength ? -1 : 1
			})
		}
		return terms;
	}

	// Handle events emitted from header component
	public header(action: string) {
		switch (action) {
			case 'openchat':
				this.openChat();
				break;
		}
	}

	public openChat() {
		const screenWidth        = screen.width;
		const screenWidthPercent = 0.25 * screenWidth;
		const minWidth           = 520;
		let width;

		const screenHeight       = 0.78 * screen.availHeight;
		let height;

		const chatParams = new URLSearchParams({
			option: 'sales',
			url: window.location.href
		});

		const chatUrl = this.chatUrl + '?' + chatParams.toString();

		if(screenWidthPercent < minWidth) {
			width = minWidth;
		} else {
			width = screenWidthPercent;
		}

		if(screenHeight > 850) {
			height = 850;
		} else {
			height = screenHeight;
		}

		window.open(chatUrl, 'AMP Chat', 'toolbar=0,scrollbars=1,location=0,statusbar=0,menubar=0,resizable=1,width=' + width + ',height=' + height);
	}
}
