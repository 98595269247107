import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';

import { ProjectComponent } from './project.component';
import { DashboardComponent as ProjectDashboard } from './dashboard/dashboard.component';
import { SiteSetupComponent } from './environment/setup/site-setup.component';
import { OverviewComponent } from './environment/overview/overview.component';
import { ServerConfigurationComponent } from './environment/configuration/configure.component';
import { W3Component } from './environment/addons/w3.component';
import { RecoveryComponent } from './environment/recover/recovery.component';
import { SecurityComponent } from './environment/security/security.component';
import { ProServicesComponent } from './environment/support/pro-services.component';
import { AgentComponent } from './environment/tools/agents/agent.component';

// Cloud Snaps.
import { WizardComponent } from './environment/publish/wizard/wizard.component';

export const ProjectRoutes: Route[] = [
	{
		path: '',
		component: ProjectComponent,
	},
	{
		path: ':project_id',
		component: ProjectComponent,
		children: [
			{
				path: '',
				component: ProjectDashboard,
			},
			{
				path: 'setup',
				component: SiteSetupComponent,
			},
			{
				path: 'overview',
				component: OverviewComponent,
			},
			{
				path: 'configure',
				component: ServerConfigurationComponent,
			},
			{
				path: 'optimize',
				component: W3Component,
			},
			{
				path: 'recover',
				component: RecoveryComponent,
			},
			{
				path: 'secure',
				component: SecurityComponent,
			},
			{
				path: 'services',
				component: ProServicesComponent,
			},
			{
				path: 'add/server',
				component: WizardComponent,
				data: {
					isUpdatePlan: false,
					offer: '',
				}
			},
			{
				path: 'resize/server',
				component: WizardComponent,
				data: {
					isUpdatePlan: true,
					offer: '',
				}
			},
			{
				path: 'agent',
				component: AgentComponent,
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(ProjectRoutes)],
	exports: [RouterModule],
})
export class ProjectRoutingModule {}
