import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../shared/material/material.module';
import { WpRestService } from './environment/services/wordpress.service';
import { DashboardComponent as ProjectDashboard } from './dashboard/dashboard.component';
import { SharedModule } from '../shared/shared.module';
import { TeamModule } from '../team/team.module';
import {
	ListComponent,
	CachedSrcDirective,
} from './collection/collection.component';

import { ProjectComponent } from './project.component';
import { MomentModule } from 'ngx-moment';
import { ConnectKeyComponent } from './dashboard/connect-key/connect-key.component';
import { W3Component } from './environment/addons/w3.component';
import { MetaService } from '../shared/services/meta.service';
import { WizardComponent } from './environment/publish/wizard/wizard.component';
import { PlanSelectorComponent } from './environment/publish/plan-selector/plan-selector.component';
import { NgxSliderModule } from 'ngx-slider-v2';
import { DataCenterSelectorComponent } from './environment/publish/data-center-selector/data-center-selector.component';
import { DeployCheckoutComponent } from './environment/publish/deploy-checkout/deploy-checkout.component';
import { CheckoutModule } from '../checkout/checkout.module';
import { AuthenticationModule, BillingModule, CoreModule } from '@central/ng-shared';
import { HostingComponent } from './environment/publish/hosting/hosting.component';
import { DomainModule } from '../domain/domain.module';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { CreateSnapshotDialogComponent } from './environment/recover/snapshots/create-snapshot-dialog/create-snapshot-dialog.component';
import { AddPublicKeyComponent } from './environment/tools/ssh-keys/add-public-key.component';
import { FileTransferComponent } from './environment/tools/sftp-reset/file-transfer.component';
import { AgentComponent } from './environment/tools/agents/agent.component';
import { AnsibleComponent } from './environment/tools/agents/ansible/ansible.component';

import { AgentOverrideComponent } from './environment/tools/agents/ansible/agent-override/agent-override.component';
import { ProjectDetailsDialogComponent } from './dashboard/project-details-dialog/project-details-dialog.component';
import { NewProjectDialogComponent } from './collection/new-project-dialog/new-project-dialog.component';
import { ConnectKeyDialogComponent } from './dashboard/connect-key/connect-key-dialog/connect-key-dialog.component';
import { SiteSetupWarnDialogComponent } from './environment/setup/site-setup-warn-dialog.component';
import { SiteSetupBlueprintDialogComponent } from './environment/setup/site-setup-blueprint-dialog.component';


// New or Refactored
import { ProjectService } from './project.service';
import { AnsibleService } from './environment/services/ansible.service';

import { EnvironmentComponent } from './environment/environment.component';
import { SiteSetupComponent } from './environment/setup/site-setup.component';
import { OverviewComponent } from './environment/overview/overview.component';
import { DiagnosticComponent } from './environment/overview/diagnostic/diagnostic.component';
import { DiagnosticTriageComponent } from './environment/overview/diagnostic/triage/triage.component';
import { ServerSoftwareComponent } from './environment/overview/diagnostic/server-software/server-software.component';
import { SiteHealthComponent } from './environment/overview/diagnostic/site-health/site-health.component';

import { ServerConfigurationComponent } from './environment/configuration/configure.component';
import { SiteUrlComponent } from './environment/configuration/site-url/site-url.component';
import { DomainChangeComponent } from './environment/configuration/site-url/domain-change/domain-change.component';
import { DomainChangeConfirmationComponent } from './environment/configuration/site-url/domain-change/domain-change-confirmation.component';
import { AutoSslComponent } from './environment/configuration/site-url/auto-ssl/auto-ssl.component';
import { PhpVersionComponent } from './environment/configuration/php-version/php-version.component';
import { NginxProfileComponent } from './environment/configuration/nginx-profile/nginx-profile.component';

import { SecurityComponent } from './environment/security/security.component';

import { MonarxComponent } from './environment/security/monarx/monarx.component';
import { MonarxPluginViewComponent } from './environment/security/monarx/plugin-view/monarx-plugin-view.component';

import { RecoveryComponent } from './environment/recover/recovery.component';

import { BackupManagerComponent } from './environment/recover/backup-manager/backup-manager.component';
import { SnapshotsComponent } from './environment/recover/snapshots/snapshots.component';

import { ResetServerComponent } from './environment/tools/reset/reset-server.component';

import { ProServicesComponent } from './environment/support/pro-services.component';
import { ManagedHostingComponent } from './environment/support/managed-hosting/managed-hosting.component';

import { JobsComponent } from './environment/tools/agents/ansible/jobs/jobs.component';

import { ProjectRoutingModule } from './project.routes';

@NgModule({
	imports: [
		ProjectRoutingModule,
		CommonModule,
		RouterModule,
		SharedModule,
		MaterialModule,
		MomentModule,
		NgxSliderModule,
		AuthenticationModule,
		CheckoutModule,
		BillingModule,
		DomainModule,
		TeamModule,
		CoreModule,
	],
	declarations: [
		ProjectComponent,

		ListComponent,
		NewProjectDialogComponent,

		ProjectDashboard,
		ProjectDetailsDialogComponent,

		ConnectKeyComponent,
		ConnectKeyDialogComponent,

		WizardComponent,
		HostingComponent,
		PlanSelectorComponent,
		DataCenterSelectorComponent,
		DeployCheckoutComponent,

		AgentComponent,
		AnsibleComponent,
		AgentOverrideComponent,
		JobsComponent,

		CachedSrcDirective,

		// New or Refactored

		// Environment
		EnvironmentComponent,
		AddPublicKeyComponent,
		FileTransferComponent,
		ResetServerComponent,

		SiteSetupComponent,
		SiteSetupWarnDialogComponent,
		SiteSetupBlueprintDialogComponent,

		OverviewComponent,
		DiagnosticComponent,
		DiagnosticTriageComponent,
		SiteHealthComponent,
		ServerSoftwareComponent,

		ServerConfigurationComponent,
		SiteUrlComponent,
		AutoSslComponent,
		DomainChangeComponent,
		DomainChangeConfirmationComponent,
		PhpVersionComponent,
		NginxProfileComponent,

		W3Component,

		RecoveryComponent,
		SnapshotsComponent,
		BackupManagerComponent,
		CreateSnapshotDialogComponent,

		SecurityComponent,
		MonarxComponent,
		MonarxPluginViewComponent,

		ProServicesComponent,
		ManagedHostingComponent,

	],
	exports: [
		PlanSelectorComponent,
		ProjectDetailsDialogComponent,
		NewProjectDialogComponent,
		ConnectKeyDialogComponent,
		ResetServerComponent,
		SiteSetupWarnDialogComponent,
		ConnectKeyComponent,
	],
	providers: [
		MetaService,
		WpRestService,
		AnsibleService,
		ProjectService,
	],
})
export class ProjectModule {}
