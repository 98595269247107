export class Post {
	public postForm(url: string, params: any, newTab = true, method = 'POST') {
		const form = document.createElement('form');
		form.setAttribute('method', method);
		form.setAttribute('action', url);
		const target = newTab ? '_blank' : '_self';
		form.setAttribute('target', target);
		form.style.display = 'none';

		for (const i in params) {
			if (params.hasOwnProperty(i)) {
				const inputs = document.createElement('input');
				inputs.setAttribute('type', 'hidden');
				inputs.setAttribute('name', i);
				inputs.setAttribute('value', params[i]);
				form.appendChild(inputs);
			}
		}

		document.body.appendChild(form);

		form.submit();
		form.remove();
	}
}
