import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
	selector: 'amp-password-create',
	templateUrl: 'password-create.component.html',
	styleUrls: [],
})
export class PasswordCreateComponent {
	@ViewChild('password') public password: any;
	public isValid = false;
	public minPasswordChar = 12;
	@Input() public readOnlyForm = false;

	public inputType = 'password';

	public getInfo() {
		return this.password
	}

	public onStrengthChanged(strength: number): void {
		let valid = false;
		if(![undefined,'',null].includes(this.password)) {
			valid = strength === 100 && this.password.length >= this.minPasswordChar;
		}
		this.isValid = valid;
	}
	public updatePassword(value: string): void {
		this.password = value;
		this.onStrengthChanged(this.password.strength);
	}
}