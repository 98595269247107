import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { StepperStep } from './amp-stepper.interface';

@Component({
	selector: 'amp-stepper',
	templateUrl: 'amp-stepper.component.html',
	styleUrls: ['amp-stepper.component.scss'],
})



export class AmpStepperComponent implements AfterViewInit {
	@Input() public steps = [];
	@Input() public initialStep = 1;
	@Output() public changedStep = new EventEmitter();
	public selectedIndex = 1;
	public currentStep: StepperStep = {
		step: 0,
		title: '',
		selected: false,
		completed: false,
		disabled: false,
		isEditable: false
	};

	public constructor(private cdr :ChangeDetectorRef) {}

	public ngAfterViewInit() {
		this.selectedIndex = this.initialStep;
		this.currentStep = this.steps[this.selectedIndex];
		this.steps[this.selectedIndex].selected = true;
		this.cdr.detectChanges();
	}

	public next(step = null) {
		if(step) {
			const newStepIdx = this.steps.findIndex(e => e.step === step);
			if(newStepIdx > -1) {
				this.selectedIndex = newStepIdx;
			}
		} else {
			if(this.selectedIndex < this.steps.length - 1) {
				this.selectedIndex++;
			} else {
				this.selectedIndex = this.steps.length - 1;
			}
		}
		
		
		for(let i=0; i<this.steps.length; i++) {
			if(i !== this.selectedIndex) {
				this.steps[i].selected = false
				this.steps[i].completed = true
			}
		}
		this.steps[this.selectedIndex].selected = true
		this.steps[this.selectedIndex].completed = false;
		this.currentStep = this.steps[this.selectedIndex];
	}

	public previous(step) {
		const newStepIdx = this.steps.findIndex(e => e.step === step);
		if(newStepIdx > -1 && newStepIdx < this.steps.length) {
			const newStep = this.steps[newStepIdx];
			if(newStep.isEditable && !newStep.disabled) {
				this.selectedIndex = newStepIdx;
				this.currentStep = this.steps[this.selectedIndex];
				this.steps[this.selectedIndex].selected = true;
				this.steps[this.selectedIndex].completed = false;
				for(let i=this.selectedIndex + 1 ; i<this.steps.length; i++) {
					this.steps[i].completed = false
					this.steps[i].selected = false
					this.steps[i].isEditable = false
				}
			}
		}
	}

	public goToStep(page) {
		const newStep = this.steps.find(e => e.title.toLowerCase() === page);
		if(newStep) {
			this.handleStep(newStep)
		}
	}

	public advanceStep(step) {
		this.handleStep(step, true)
	}

	public handleStep(step, force = false) {
		const prevStep = {...this.currentStep}
		if(!this.steps.includes(this.selectedIndex)) {
			const currentStepIdx = this.steps.findIndex(e => e.title.toLowerCase() === prevStep.title.toLowerCase())
			if(currentStepIdx > -1) {
				this.selectedIndex = currentStepIdx;
			} else {
				this.selectedIndex = this.steps.length - 1;
				this.currentStep = this.steps[this.selectedIndex];
			}
		}
		if((step.isEditable && this.steps[this.selectedIndex] !== step) || force) {
			const currentStep = this.steps[this.selectedIndex];
			const newStep = step
			if(newStep.step >= currentStep.step) {
				this.next(force ? step.step : null)
			} else {
				this.previous(newStep.step)
			}
			this.changedStep.emit({toStep: newStep, fromStep: prevStep})
		}
	}

	public getStepperClasses(step) {
		let classString = '';
		if(step.selected || (step.completed && step.isEditable)) {
			classString = 'ctw-text-blue-800 ctw-cursor-pointer';
			if(step.selected) {
				classString += ' ctw-underline'
			}
			return classString;
		}
		if(step.isEditable === false || step.step > this.steps[this.selectedIndex].step) {
			classString = 'ctw-text-gray-600 ctw-text-opacity-50';
			if(step.completed && step.disabled === false) {
				classString += ' ctw-cursor-pointer';
			}
			return classString;
		}
	}
}