import { Component, EventEmitter, Input, OnInit, Output, } from '@angular/core';
import { AmpOpApiService } from '../amp-op-api/amp-op-api.service';
import { AmpLocalStorageService } from '../amp-local-storage-service';

interface Salesperson {
    id: string;
    name: string;
}

@Component({
	selector: 'amp-internal-only',
	templateUrl: 'amp-internal-only.component.html',
	styleUrls: [],
})
export class AmpInternalOnlyComponent implements OnInit {
	@Output() public internalOnlyChanged = new EventEmitter<object>();
	public status = 'loading';
	public customerType = 'Live';
	public customerTypes = ['Live','Test','Employee','Comp'];
    public salespersonData: Salesperson[];
    public salespersonId = 0;
    public salespersonId2 = 0;
	public cart: object;

	public constructor(
		public ampOpApiService: AmpOpApiService,
		public localStorage: AmpLocalStorageService,
	) {}

	public ngOnInit() {
		this.ampOpApiService.getSalespersonData().subscribe((resp: object) => {
			if(resp) {
				if ('salesperson' in resp) {
					this.salespersonData = resp.salesperson as Salesperson[];
				}
				if('cart' in resp) {
					this.cart = resp.cart as object;
					if(this.cart.hasOwnProperty('SalesPersonId')) {
						this.salespersonId = parseInt(this.cart['SalesPersonId'], 10);
					}
					if(this.cart.hasOwnProperty('SalesPersonId2')) {
						this.salespersonId2 = parseInt(this.cart['SalesPersonId2'], 10);
					}
				}
				this.getStoredInternalInfo();
			}
		});
	}

	public getStoredInternalInfo() {
		const internalInfo = this.ampOpApiService.getInternalOnlyInfo();
		if(internalInfo.hasOwnProperty('SalesPersonId')) {
			this.salespersonId = parseInt(internalInfo['SalesPersonId'], 10);
		}
		if(internalInfo.hasOwnProperty('SalesPersonId2')) {
			this.salespersonId2 = parseInt(internalInfo['SalesPersonId2'], 10);
		}
		if(internalInfo.hasOwnProperty('CustomerType')) {
			this.customerType = internalInfo['CustomerType'];
		}
	}

	public internalUpdated() {
		this.internalOnlyChanged.emit({
			SalesPersonId: this.salespersonId,
			SalesPersonId2: this.salespersonId2,
			CustomerType: this.customerType,
		});
	}
}
