<ng-container>
	<div class="wrap animate__animated animate__fadeIn ctw-mx-auto ctw-max-w-4xl ctw-mt-8">
		<div class="ctw-flex ctw-flex-row ctw-items-center">
			<h2>Monarx Security</h2>
			<div *ngIf="this.state === 'success' || this.state === 'updating' || this.state === 'failed'" class="ctw-ml-4">
				<mat-slide-toggle *ngIf="this.available" (toggleChange)="toggleInstall()" [(ngModel)]="monarxInstalled" name="monarxInstalled" color="primary">
					<span *ngIf="!monarxInstalled" class="ctw-text-red-700">Disabled</span>
					<span *ngIf="monarxInstalled" class="ctw-text-green-700">Enabled</span>
				</mat-slide-toggle>
				<central-progress-button *ngIf="!this.available" color="primary" [disabled]="true">
					<mat-icon class="ctw-mr-2">lock_clock</mat-icon> Unavailable During Update
				</central-progress-button>
			</div>
		</div>
		<dash-card cardTitle="Monarx Security"
			icon="no_encryption" [disabled]="true" [transparent]="true">
			<ng-container subTitleHTML *ngIf="monarxInstalled">
				<span class="sub-title ctw-text-base">
					<span class="ctw-text-red-700">
						Disabled
					</span>
				</span>
			</ng-container>
			<mat-progress-bar *ngIf="this.state === 'loading' || this.state === 'updating'" mode="indeterminate" color="primary"></mat-progress-bar>
			<div *ngIf="this.state === 'failed'">
				<p class="ctw-mb-4">
				There was a problem retrieving the Monarx status. You can try reinstalling with the controls above or contact our support if the issue persists.
				</p>
			</div>
			<div *ngIf="this.state === 'success'"
				class="dash-card-content animate__animated animate__fadeIn">
				<p class="ctw-mb-4" *ngIf="!monarxInstalled">
					<span class="ctw-inline-flex"><a
							target="_blank"
							href="https://www.inmotionhosting.com/support/security/monarx-security/">Monarx Security</a>
						<mat-icon
							class="ctw-ml-1 ctw-mt-1 ctw-mr-1 ctw-w-3 ctw-h-3.5 ctw-text-sm">open_in_new</mat-icon></span>
					is not currently installed on your server.
				</p>
				<div *ngIf="monarxInstalled">
					<p class="ctw-mb-4" *ngIf="monarxInstalled">
						<span class="ctw-inline-flex"><a
								target="_blank"
								href="https://www.inmotionhosting.com/support/security/monarx-security/">Monarx Security</a>
							<mat-icon
								class="ctw-ml-1 ctw-mt-1 ctw-mr-1 ctw-w-3 ctw-h-3.5 ctw-text-sm">open_in_new</mat-icon></span>
						is currently installed on your server.
					</p>
					<div *ngIf="this.available && this.monarxPlugin.status === 'success'">
						<a
							href="{{ this.monarxPlugin.url }}"
							target="_blank"
							mat-stroked-button
							color="primary">
							Monarx Dashboard
							<mat-icon class="open-in-new">open_in_new</mat-icon>
						</a>
						<central-monarx-plugin-view [monarxUrl]="this.monarxPlugin.url"></central-monarx-plugin-view>
					</div>
					<mat-progress-spinner
						*ngIf="this.monarxPlugin.status === 'loading'"
						class="inline-spinner"
						mode="indeterminate"
						color="primary"
						diameter="48">
					</mat-progress-spinner>
					<p class="ctw-text-red-700" *ngIf="this.monarxPlugin.status === 'error'">
						Unable to retrieve Monarx plugin URL. Please try again later or contact support for assistance.
					</p>
				</div>
			</div>
		</dash-card>
	</div>
</ng-container>
