import {
	Location,
	LocationStrategy,
	PathLocationStrategy,
} from '@angular/common';
import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	AfterViewInit,
	Output,
	ViewChild,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { RecaptchaComponent } from 'ng-recaptcha';
import {
	ApiService,
	AuthService,
	ConfigurationService,
	RecurlyCreditCardComponent,
	AccountService,
	ProfileService,
	OptimizelyService,
} from '@central/ng-shared';
import { Config } from '../../shared/config/env.config';
import { LocalStorageService } from '@central/ng-shared';
import { TrackingService, GoogleAnalyticsService } from '@central/ng-shared';
import { LogrocketService } from '@central/ng-shared';
import { CartService } from '../cart/cart.service';
import { UpgradeDialogComponent } from '../upgrade-dialog/upgrade-dialog.component';
import { PaymentService } from './payment.service';
import { ScriptService } from '@central/ng-shared';
import { Observable, Subscription, throwError } from 'rxjs';

// tslint:disable-next-line:no-submodule-imports
import { catchError, filter } from 'rxjs/operators';
import { BrandingService } from '@central/ng-shared';
import { LicenseService } from '../../shared/license/license.service';
import { ProjectService } from '../../project/project.service';
import { PaymentMethodComponent } from '../../amp/payment-method/payment-method.component';
import { AmpOpApiService } from '../../amp/amp-op-api/amp-op-api.service';
import { AmpAccountAuthComponent } from '../../amp/account-auth/account-auth.component';
import { AppService } from '../../app.service';
import { BillingAddressComponent } from '../../amp/billing-address/billing-address.component';
import { PaypalButtonComponent } from '../../amp/paypal-button/paypal-button.component';
import { AmpConfigComponent } from '../../amp/amp-config/amp-config.component';
import { AmpOffersComponent } from '../../amp/amp-offers/amp-offers.component';
import { NetworkValidationService } from '../../shared/network-validation/network-validation.service';
import { AmpInternalOnlyComponent } from '../../amp/amp-internal-only/amp-internal-only.component';

@Component({
	selector: 'payment-form',
	templateUrl: 'payment.component.html',
	styleUrls: ['payment.component.scss'],
	providers: [
		Location,
		{ provide: LocationStrategy, useClass: PathLocationStrategy },
	],
})
export class PaymentComponent implements OnDestroy, OnInit, AfterViewInit {
	@Input() public packages: any[] = null;
	@Input() public ampTerms: any[] = null;
	@Input() public hideGuarantee = false;
	@Input() public hideAmpConfiguration = true;
	@Input() public hideAmpOffers = true;
	@Input() public upsellAmpOffers: any[] = [];
	@Input() public includeReceiptQuery = false;
	@Input() public terms: string;
	@Input() public hideTerms = false;
	@Input() public cardClass = 'card-medium';
	@Input() public includeBackButton = true;
	@Input() public useCartService = false;
	@Input() public excludedInputs = ['email'];
	@Input() public returnWpUrl: string;
	@Input() public disablePostPurchase: string;
	@Input() public discountCode: string;
	@Input() public term: string;
	@Input() public organizationId: string;
	@Input() public projectId: string;
	@Input() public discountObject: object;
	@Input() public couponData: any;
	@Input() public isUpdatePlan = false;
	@Input() public isAddPlan = false;
	@Input() public subscriptionUuid: string;
	@Input() public forceLogin = false;
	@Input() public loginModalTitle: string;
	@Input() public loginModalSubTitle: string;
	@Input() public minimalForm = false;
	@Input() public checkoutMode = 'default';
	@Input() public readOnlyForm = false;
	@Input() public dataCenters = [];
	@Input() public origin: string;
	@Input() public vatTax = false;
	@Input() public ampStepper: any;
	@Input() public ampCartStatus: string;

	@Output() public paymentBack = new EventEmitter();
	@Output() public paymentSubmit = new EventEmitter();
	@Output() public paymentSuccess = new EventEmitter();
	@Output() public paymentFailure = new EventEmitter();
    @Output() public paymentReady = new EventEmitter();

	@Output() public update = new EventEmitter();
	@Output() public cancel = new EventEmitter();

	@Output() public setProjectId = new EventEmitter();

	@Output() public termChanged = new EventEmitter();
	@Output() public dataCenterChanged = new EventEmitter();
	@Output() public configChanged = new EventEmitter();
	@Output() public configComplete = new EventEmitter();
	@Output() public removeCartItem = new EventEmitter();
	@Output() public upsellAmpOfferAdded = new EventEmitter();

	@ViewChild('contactInputs') public contactInputs: any;
	@ViewChild('form') public form: any;
	@ViewChild(RecurlyCreditCardComponent, { static: false })
	public recurlyCC: any;
	@ViewChild('discount', { static: true }) public discount: any;
	@ViewChild('cart') public cart: any;
	@ViewChild(RecaptchaComponent) public captcha: RecaptchaComponent;

	@ViewChild(PaymentMethodComponent) public ampPayment: PaymentMethodComponent;
	@ViewChild(PaypalButtonComponent) public paypalInfo: PaypalButtonComponent;
	@ViewChild(AmpAccountAuthComponent) public ampAccountInfo: AmpAccountAuthComponent;
	@ViewChild(AmpConfigComponent, {static: true}) public ampConfig: AmpConfigComponent;
	@ViewChild(AmpOffersComponent) public ampOffers: AmpOffersComponent;
	@ViewChild(AmpInternalOnlyComponent) public ampInternalOnly: AmpInternalOnlyComponent;

	public errorMessage = '';
	public receipt: any;
	public isNewUser = false;
	public captchaSubmitted = false;
	public captchaRequired = true;
	public state = 'pending';
	public isPreviousOrder = false;
	public cartLoaded = false;
	public hasRecurring = false;
	public processingFreeCart = false;
	public phoneIsValid164 = true;
	public defaultFailMessage =
		'We were unable to complete your request. Please try again later.';
	public captchaKey = Config['recaptcha']['publicKeyInvisible'];
	public paymentInfoCompleted = false;
	public termsAgree = false;
	public routeSubscription: any;
	private captchaResponse: string;
	private orderInitiated = false;
	public paymentMethod: any = {};
	public fetchState = 'loading';
	public changeCard = false;
	public updateState = 'pending';
	public hasInternalBilling = false;
	public selectedMethod = 'new';
	public message: string;
	public subscription: Subscription;
    public useTeamSelector = false;
    public teamPurchase = 'Purchase'
	public ampDomain = {};
	public ampDomainOnly = false;
	public customerType = 'Live';
	public salespersonId = 0;
	public salespersonId2 = 0;
	public dataCenter = 2;
	public ampCatalog: any = [];
	public accountType = 'new';
	public organizationEmail: string;
	public ampPaymentMethod = 'Credit Card';
	public ampSalesDiscount = 0;
	public payPalScriptUrl: string;
	public paypalButtons: any = {};
	public paypalLoaded = false;
	public dataCenterMap = {
		'iad': 1,
		'lax': 2,
		'ams': 3,
	}
	public ampCartTotal;
	public showGoToAmpButton = 'pending';
	public progressMessage = {};
	public progressAmount = 0;
	public progressMessages = [
		{ msg: 'Submitting your order', time: 3000, type: 'any'},
		{ msg: 'Deploying the Gnomes', time: 2000, type: 'any'},
		{ msg: 'Processing your payment', time: 4000, type: 'any'},
		{ msg: 'We did say Gnomes. You read that right.', time: 3000, type: 'any'},
		{ msg: 'Attempting to create your account', time: 6000, type: 'new'},
		{ msg: 'Attempting to add products to your account', time: 6000, type: 'existing'},
		{ msg: 'Awaiting Confirmation', time: 0, type: 'any'},
	];

	public publicNetwork = true;

	private subscriptions = [];

	constructor(
		public configurationService: ConfigurationService,
		public appService: AppService,
		public paymentService: PaymentService,
		public trackingService: TrackingService,
		public googleAnalytics: GoogleAnalyticsService,
		public logrocketService: LogrocketService,
		public scriptService: ScriptService,
		public cartService: CartService,
		public dialog: MatDialog,
		public brandingService: BrandingService,
		private location: Location,
		private route: ActivatedRoute,
		private router: Router,
		private licenseService: LicenseService,
		private localStorage: LocalStorageService,
		private authService: AuthService,
		public apiService: ApiService,
		private projectService: ProjectService,
		private accountService: AccountService,
		public profileService: ProfileService,
		public ampOpApiService: AmpOpApiService,
		public optimizelyService: OptimizelyService,
		public readonly networkValidation: NetworkValidationService,
	) {
		this.routeSubscription = this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe(() => this.displayPreviousCompletion());
		if (
			Config.production &&
			'InMotion Hosting' !==
				this.configurationService.config.brandConfig.reseller
		) {
			this.scriptService.loadScript('postaffiliatepro').then(() => {
				try {
					window['PostAffTracker'].setAccountId('default1');
					window['PostAffTracker'].track();
				} catch (err) {
					console.log(err);
				}
			});
        }
		this.networkValidation.publicNetwork$.subscribe(publicNetwork => {
			this.publicNetwork = publicNetwork;
			if(publicNetwork === false && this.checkoutMode === 'amp') {
				this.captchaRequired = false;
			}
		});
	}

	public ngOnInit() {
		// If terms are hidden, set to agreed so logic works properly.
		if ( this.checkoutMode === 'inline' ) {
			this.termsAgree = true;
			console.log(this.termsAgree );
		}

		if(this.checkoutMode === 'amp') {
			this.fetchState = 'pending';
			this.ampLogin();
			this.discount.autoload(this.discountCode);
		}

		if(this.checkoutMode === 'default' || this.checkoutMode === 'inline') {
			this.subscriptions.push(
				this.profileService.onProfileUpdate.subscribe((profile: any) => {
					this.setOrganization();
				})
			)

			// Pull packages from cart service if undefined.
			if (!this.packages || !this.packages.length) {
				// Pull in cart packages.
				if (this.useCartService) {
					this.packages = this.cartService.get() || [];
				}
			}

			this.packages.forEach((product) => {
				if (product.options?.data_center) {
					this.dataCenter = this.dataCenterMap[product.options.data_center];
				}
			});

			this.recaptchaCheck();
			this.discount.autoload(this.discountCode);

			if (undefined === this.term) {
				this.term = 'yearly';
			}
			this.subscription = this.accountService.currentMessage.subscribe(
				(message) => {
					// Check for default message before fetching since we won't know ID right away.
					if (message !== 'default message source') {
						this.message = message;
						this.organizationId = message;
						//this.fetchBillingInformation();
					}
				}
			);
		}
	}

	ngAfterViewInit(): void {
		this.cart?.cartUpdated.subscribe(() => {
			if(this.checkoutMode === 'default' || this.checkoutMode === 'inline') {
				this.term = this.cart.term;
				this.packages = this.cart.packages;
				this.setDataCenters();
			}
		});
		if(this.checkoutMode === 'amp') {
			this.contactInputs?.autofillControls['country'].control.valueChanges.subscribe(
				(value: string) => {
					this.handleCountryInfo(value).subscribe()
					this.ampCheckAvailability(value);
				}
			);
		}
	}

	public ngOnDestroy() {
		this.routeSubscription.unsubscribe();
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
		this.subscriptions.forEach(sub => sub.unsubscribe())
	}

	public handleCartUpdate() {
		if(this.checkoutMode === 'amp') {
			this.term = this.cart.term;
			this.packages = this.cart.packages;

			this.ampConfig.packages = this.packages
			this.ampConfig.setup()
			this.termChanged.emit();
		}
	}

	public removeItemFromCart(item) {
		if(['domain', 'hosting'].includes(item.amp_product_type)) {
			const hasDomain = this.packages.filter(e => e.type === 'domain').length > 0;
			const hasHosting = this.packages.filter(e => e.type === 'hosting').length > 0;
			let empty = false;
			if(item.amp_product_type === 'hosting') {
				if(hasDomain) {
					this.removeCartItem.emit('hosting');
				} else {
					empty = true;
				}
			}
			if(item.amp_product_type === 'domain') {
				if(hasHosting) {
					this.removeCartItem.emit('domain');
				} else {
					empty = true;
				}
			}
			if(empty) {
				this.removeCartItem.emit('empty');
			}
		} else if(item.amp_product_type === 'addon') {
			this.ampConfig.removeCartItem(item);
		}
	}

	public changedConfiguration(configs) {
		configs.forEach(config => {
			const configIndx = this.packages.findIndex(e => e.info.ProductType === 'Addon:'+config.type);
			if(configIndx > -1) {
				this.packages.splice(configIndx, 1);
			}
			if(config?.selected?.hasOwnProperty('AddonDetailsId')) {
				const pkg = this.ampOpApiService.createCatalogPackage(config.selected);
				if(this.ampConfig.selectedCartInfo.hasOwnProperty('Addon')) {
					let setupPrice = 0;
					const cartAddons = Object.values(this.ampConfig.selectedCartInfo['Addon']);
					const catProductInfo = cartAddons.find((e: { Id: number; }) => e.Id === config?.selected?.AddonDetailsId);
					if(catProductInfo && catProductInfo.hasOwnProperty('SetupPrice') && catProductInfo.hasOwnProperty('SetupCharged') && catProductInfo['SetupCharged'] === true) {
						setupPrice = Number(parseFloat(catProductInfo['SetupPrice']));
						(pkg as any).setup_price = setupPrice;
						(pkg as any).price_in_cents += (setupPrice * 100);
					}
				}
				if(config.hasOwnProperty('Required')) {
					pkg['info']['Required'] = true;
				}
				this.packages.push(pkg);
			}
		})
		const hosting = this.packages.find(e => e.type === 'hosting');
		let allowedConfigIds = [];
		let allowedAddonIds = [];
		if(hosting.hasOwnProperty('configs')) {
			allowedConfigIds = hosting?.configs?.filter(e => this.ampConfig.availableAddonIds.includes(e.AddonDetailsId)).map(e => e.AddonDetailsId) || []
		}
		if(hosting.hasOwnProperty('addons')) {
			allowedAddonIds = hosting.addons.filter(e => this.ampConfig.availableAddonIds.includes(e.AddonDetailsId)).map(e => e.AddonDetailsId) || []
			const included = hosting.addons.filter(e => this.ampConfig.availableAddonIds.includes(e.AddonDetailsId)) || []
			included.forEach(i => {
				const addonIndx = this.packages.findIndex(p => p.info.AddonDetailsId === i.AddonDetailsId)
				if(addonIndx === -1 && (i.AddonAutoSelected || i.AddonIncluded)) {
					this.packages.push(this.ampOpApiService.createCatalogPackage(i))
				}
			})
		}
		const newPackages = this.packages.filter(
			e => e.type !== 'addon' ||
			(e.type === 'addon' && (e.label === 'Domain Privacy' ||
			allowedConfigIds.includes(e.info.AddonDetailsId) ||
			allowedAddonIds.includes(e.info.AddonDetailsId)))
		);

		this.cart?.updateAmpAddons(newPackages, this.ampConfig.availableAddonArray);
	}

	public setOrganization() {
		if (this.projectService.project) {
			this.organizationId = this.projectService.getAccountId();
			this.projectId = this.projectService.project.id;
		} else {
			this.organizationId =
				this.profileService.data.options.public_selected_team ||
					this.profileService.data.account_access[1];
		}

		if (this.origin === 'guide' &&
			this.profileService.data.account_access
				.filter((account) => account.type === 'organization' && account.role === 'owner').length > 1) {
			this.useTeamSelector = true;
			this.packages.forEach((product) => {
				switch (product.planCode) {
					case 'email_account':
						this.teamPurchase = 'Email';
						break;
					case 'domain-gtld-1':
						this.teamPurchase = 'Domain';
						break;
					default:
						this.teamPurchase = 'Project';
						break;
				}
			});
		}

		this.fetchBillingInformation();
	}

    public accountChanged($event) {
		setTimeout(() => {
			this.accountService.updateAccount($event['account_id']);
			this.organizationId = $event['account_id'];
			this.loadForm({});
			this.fetchBillingInformation();
		}, 0);
	}

	public formSubmission() {
		if (this.captchaRequired) {
			this.captchaSubmitted = true;
			this.captcha.execute();
			setTimeout(() => (this.captchaSubmitted = false), 4000);
		} else {
			this.submit();
		}
	}

	public afterCartInit() {
		if(this.checkoutMode === 'default' || this.checkoutMode === 'inline') {
			this.hasRecurring = this.cart?.hasRecurring();
			this.cartLoaded = true;
			this._setupAuthEvent();

			// The cart has been loaded, see if this order is eligible for discounts.
			this.discount.findDiscounts(this.packages);

			if (!this.orderInitiated && !this.isPreviousOrder) {
				this.orderInitiated = true;
				this.trackOrderState('orderInitiated');
			}
		}
		if(this.checkoutMode === 'amp') {
			this.setAdditionalAmpData();
		}
	}

	public cartFailed() {
		return (
			this.cartLoaded &&
			'success' !== this.state &&
			this.cart &&
			'failed' === this.cart.status
		);
	}

	public invalidInputState(input: any) {
		const dirty = this.isDirty(input);
		return !input.valid && dirty && this.state !== 'submitted';
	}

	public isDirty(input: any) {
		return (input.dirty && input.touched) || this.state === 'failed';
	}

	/**
	 * Track the current state of an order.
	 *
	 * @since 1.1
	 *
	 * @param   state  The event to pass on to the tracking service.
	 */
	public trackOrderState(state: string, result?: any) {
		this.trackingService.track(state, {
			cart: this.cart,
			response: result,
		});
	}

	private flattenProducts() {
		let flattenedAddons = [];
		for (const product of this.receipt.products) {
			if (product.code === 'wpc_tukp') {
				product.article =
					'https://www.boldgrid.com/support/boldgrid-backup/';
			} else if (product.code === 'wpc_crio') {
				product.article =
					'https://www.boldgrid.com/support/boldgrid-crio/';
			} else if (product.code === 'wpc_ppbp') {
				product.article =
					'https://www.inmotionhosting.com/support/product-guides/wordpress-hosting/central/addons/post-page-builder-premium/';
			}
			flattenedAddons = [...flattenedAddons, ...(product.addons || [])];
			delete product.addons;
		}

		return [...this.receipt.products, ...flattenedAddons];
	}

	public showForms() {
		return (
			this.state !== 'success' &&
			this.state !== 'submitted' &&
			this.fetchState !== 'loading' &&
			!this.processingFreeCart
		);
	}

	public hasError() {
		return this.state === 'failed' && this.errorMessage;
	}

	public success(receipt: any) {
		this.receipt = receipt;
		this.receipt.products = this.flattenProducts();

		this.receipt.products.forEach((product) => {
			if (product.type === 'hosting') {
				if (undefined !== product.resource_ids?.project_id) {
					this.setProjectId.emit(product.resource_ids.project_id);
				}
			}
		});

		this.state = 'success';
		this.paymentSuccess.emit();

		this.trackOrderState('purchase', receipt);
		this.scrollTop();

		this.trackingService.track('pageview', {
			page_path: '/checkout/order-complete',
		});

		// Store payment results.
		if (this.isUpdatePlan && this.includeReceiptQuery && receipt.invoice.invoice_number) {
			this.location.go(
				this.location.path().split('?')[0],
				'order=' + receipt.invoice.invoice_number
			);
			this.localStorage.setItem('recentPurchase', this.receipt);
		}

		if (receipt['login_token']) {
			this.authService.setLogin(receipt['login_token'], true);
		}

		// Refresh caches
		this.licenseService.fetch(false);
		this.appService.getPurchases(false).subscribe(() => {
			this.appService.getFirstPartyDomains(false).subscribe();

			if (this.isUpdatePlan || this.isAddPlan) {
				this.appService.getProjects(false).subscribe(() => {
					this.appService.persistentStorage.removeItem('project_id');
					this.router.navigate(
						['/projects', this.projectService.project?.id],
					)
				});
			}
		});
	}

	public fail(error: string) {
		this.paymentFailure.emit();
		this.state = 'failed';
		this.errorMessage = error;
		this.captcha.reset();
	}

	public scrollTop() {
		window.scrollTo(0, 0);
	}

	public processBasicKey() {
		this.processingFreeCart = true;
		this.state = 'submitted';

		this.process({
			cart: {
				plans: [
					{
						code: 'wpc_project_yearly',
						addons: [
						],
					},
				],
			},
		});
	}

	public recaptchaCheck() {
		this.authService
			.preAuthCheck('product-add')
			.subscribe(
				(validRequest) => (this.captchaRequired = !validRequest)
			);
	}

	/**
	 * Handles the submission of the payment form.
	 *
	 * This method validates the form data and processes the payment through either
	 * the AMP system or the credit card processing system depending on the checkout mode.
	 *
	 * The method performs the following steps:
	 * 1. Validates the form data based on form type (minimal or full)
	 * 2. Checks for AMP-specific validations if in AMP mode
	 * 3. Prevents double-charging by checking current state
	 * 4. Processes the payment if all validations pass
	 * 5. Handles failure cases with appropriate error messages
	 *
	 * @param {string|null} captchaResponse - The response token from reCAPTCHA verification
	 * @returns {Promise<void>}
	 *
	 * @emits paymentSubmit - When payment submission begins
	 * @emits paymentFailure - When payment submission fails
	 *
	 * @throws Will not throw errors directly, but will set error state and message
	 */
	public async submit(captchaResponse = null) {
		let submitValid = this.form.valid;

		if (this.minimalForm) {
			await this.contactInputs.setAddress();
			submitValid = this.form.valid && this.contactInputs.isFormValid();
		}

		if(this.checkoutMode === 'amp') {
			submitValid = this.accountInfoValid() && this.form.valid;
			if(this.accountType === 'new') {
				if(this.contactInputs?.model.phone === "") {
					this.contactInputs?.updatePhone();
				}
				if(this.contactInfoValid() === false) {
					submitValid = false;
				}
			}
			if(submitValid === true && this.ampPayment?.cardId === 'new') {
				submitValid = this.ampPayment?.creditcard?.isValid();
			}
		}
		this.captchaResponse = captchaResponse;

		// DONT CHARGE TWICE!!!!!
		const alreadyProcessed =
			this.state === 'submitted' || this.state === 'success';
		if (alreadyProcessed || (!captchaResponse && this.captchaRequired)) {
			return;
		}

		if (submitValid) {
			this.paymentSubmit.emit();
			this.state = 'submitted';
			if(this.checkoutMode === 'amp') {
				this.contactInputs.updateStatus = 'submitted';
				this.errorMessage = '';
				this.identifyLogRocket();
				this.scrollTop();
				this.processOpOrder();
			} else {
				this.isNewUser = !this.authService.isLoggedIn();
				if (this.authService.isGuideLocked()) {
					this.profileService.saveSalespersonTracking();
					this.profileService.saveImpactPartnerTracking();
				}
				this.contactInputs.updateStatus = 'submitted';
				this.errorMessage = '';
				this.scrollTop();
				this.processCreditOrder();
			}

		} else {
			this.paymentFailure.emit();
			this.scrollTop();
			this.state = 'failed';
			this.contactInputs.updateStatus = 'failed';
			this.errorMessage = 'Please complete the form fields in red.';
			this.captcha.reset();
			if(this.checkoutMode === 'amp') {
				this.progressAmount = 0;
				this.progressMessage = '';
			}
		}
	}

	/**
	 * Send Tracking Data from purchase response to Optimizely
	 */
	private handleOptimizelyEvents(data) {
		if(window.hasOwnProperty('optimizely')) {
			const chargedAmount = parseFloat(data.Amount);
			this.optimizelyService.send('trackEvent', 'any-sale', { 'revenue': chargedAmount * 100 });

			if ( data.HasBoldgrid === 1 ) {
				this.optimizelyService.send('trackEvent', 'bgsale');
			}

			if ( typeof data.Products != 'undefined' ) {
				if ( data.Products.length > 0 ) {
					let saleEvent = '';
					let planEvent = '';
					for (const product of data.Products) {
						const productName = product.Name.toLowerCase().replace(/\s+/g, "-");
						let productTerm  = product.ProductTerm.toLowerCase();
						const productTypes = product.Types;

						if ( productTerm.indexOf( 'year' ) > 0 ) {
							productTerm = parseInt( productTerm, 10 ) * 12;
						} else {
							productTerm = parseInt( productTerm, 10 );
						}

						if ( isNaN( productTerm ) ) {
							productTerm = 0;
						}

						for (const productType of productTypes) {
							const type      = productType.toLowerCase().replace(/\s+/g, "-");
							saleEvent = type + '-sale';
							planEvent = type  + '-' + productTerm;
							this.optimizelyService.send('trackEvent', saleEvent);
							this.optimizelyService.send('trackEvent', planEvent);
						}

						saleEvent = productName + '-sale';
						planEvent = productName  + '-' + productTerm;
						this.optimizelyService.send('trackEvent', saleEvent);
						this.optimizelyService.send('trackEvent', planEvent);

						if ( typeof product.Addons != 'undefined' ) {
							this.optimizelyService.send('trackEvent', 'addon-sale');
							for (const addon of product.Addons) {
								const addonName = addon.Name.toLowerCase().replace(/\s+/g, "-");
								let addonType = addon.Type.toLowerCase().replace(/\s+/g, "-");
								let addonTerm = addon.ProductTerm.toLowerCase();
								const addonTypes = addon.Types;

								if ( addonTerm.indexOf('year') > 0 ) {
									addonType = parseInt( addonType, 10  ) * 12;
								} else {
									addonType = parseInt( addonType, 10  );
								}

								if ( isNaN( addonTerm ) ) {
									addonTerm = 0;
								}

								for (let i = 0; i < addonTypes.length; i++) {
									let type = addonTypes[i];
									if ( i > 0 ) {
										type = type.toLowerCase().replace(/\s+/g, "-");
										saleEvent = type + '-sale';
										planEvent = type  + '-' + addonTerm;
										this.optimizelyService.send('trackEvent', saleEvent);
										this.optimizelyService.send('trackEvent', planEvent);
									}
								}

								saleEvent = addonName + '-sale';
								planEvent = addonName  + '-' + addonTerm;
								this.optimizelyService.send('trackEvent', saleEvent);
								this.optimizelyService.send('trackEvent', planEvent);
							}
						}

						if ( typeof product.HasBoldgrid != 'undefined' ) {
							this.optimizelyService.send('trackEvent', 'auto-install-sale');
							this.optimizelyService.send('trackEvent', 'auto-install-wordpress');
							this.optimizelyService.send('trackEvent', 'auto-install-boldgrid');
						} else if ( typeof product.AutoInstall != 'undefined' ) {
							const autoinstall = product.AutoInstall.toLowerCase().replace(/\s+/g, "-");
							this.optimizelyService.send('trackEvent', 'auto-install-sale');
							this.optimizelyService.send('trackEvent', 'auto-install-' + autoinstall);
						}
					}
				}
			}
		}
	}

	/**
	 * If a hash was provided with a recent order number, pull the most recent order
	 * from local storage and display it.
	 *
	 * @since 1.13.0
	 */
	private displayPreviousCompletion() {
		const order = this.route.snapshot.queryParamMap.get('order') || '';
		this.isPreviousOrder = false;

		if (order) {
			const receipt = this.localStorage.getItem('recentPurchase');
			if (
				this.isUpdatePlan ||
				(
					receipt &&
					receipt.invoice &&
					+order === +receipt.invoice.invoice_number
				)
			) {
				this.receipt = receipt;
				this.state = 'success';
				this.isPreviousOrder = true;
			}
		}

		// User pressed back button after order completion.
		if (
			!this.isPreviousOrder &&
			this.receipt &&
			'CheckoutComponent' === this.route.component['name']
		) {
			window.location.reload();
		}
	}

	private process(chargeData: object) {
		// Because this may be called directly.
		this.state = 'submitted';

		chargeData['cart'] = chargeData['cart'] || this.cart.getPayload();
		chargeData['cart'].options.project_id = this.projectService.project?.id;

		if (this.profileService.data.onboarded) {
			chargeData['organizationId'] = this.profileService.data.options.public_selected_team;
		} else {
			chargeData['organizationId'] =
				this.profileService.data.account_access[1].account_id;
		}

		chargeData['subscriptionUuid'] = this.subscriptionUuid;

		this.paymentService.process(chargeData, this.isUpdatePlan).subscribe(
			(res: any) => {
				this.success(res);
			},
			(message: string) => {
				const errorMessage = message || this.defaultFailMessage;

				this.fail(errorMessage);
				this.scrollTop();
				this.trackingService.track('error', {
					category: 'payment',
					name: 'failed',
					data: errorMessage,
				});
			}
		);
	}

	private async processCreditOrder() {
		let tokenResponse;

		if (this.selectedMethod === 'new') {
			const purchaseData = this.contactInputs.model.getData();

			try {
				tokenResponse = await this.recurlyCC.getToken(purchaseData);
			} catch (e) {
				this.state = 'failed';
				this.errorMessage =
					e.message || 'Invalid form. Please complete all fields.';
				return;
			}
		}

		const chargeData = {
			token: tokenResponse,
			organizationId: this.organizationId || null,
			email: this.contactInputs.model.email,
			captcha: this.captchaResponse,
			couponCode: this.couponData?.coupon_code || null,
			currency: this.cart.currency,
		};

		this.process(chargeData);
	}

	private async processOpOrder() {
		const cartitems = this.cart.cart;
		const items = [];
		cartitems.forEach(e => {
			e.domain = this.ampDomain['name'];
			items.push(e)
		})
		const opData = {
			payment: this.ampPayment.getInfo(),
			items: items,
			domainInfo: this.ampDomain
		}
		const user = this.contactInputs.model.getData();
		const email = this.ampAccountInfo.validEmail();
		user.email = email;
		
		if(this.ampAccountInfo.accountType === 'new') {
			if(this.publicNetwork === false) {
				this.ampInternalOnly.internalUpdated();
			}
			if(this.ampAccountInfo?.authType === 'password') {
				user.password = this.ampAccountInfo.newPassword.password;
			}
			if(this.publicNetwork === false) {
				user.customerType = this.ampInternalOnly.customerType;
			} else {
				user.customerType = this.customerType;
			}
			opData['user'] = user;
			if(this.publicNetwork === false) {
				if(this.ampInternalOnly.salespersonId > 0) {
					opData['SalesPersonId'] = this.ampInternalOnly.salespersonId.toString();
				}
				if(this.ampInternalOnly.salespersonId2 > 0) {
					opData['SalesPersonId2'] = this.ampInternalOnly.salespersonId2.toString();
				}
			}
		} else {
			if(opData.hasOwnProperty('billing') && opData.payment.hasOwnProperty('billing')) {
				opData['billing'] = opData.payment['billing'];
			} else {
				opData['billing'] = user;
			}
		}


		if(this.dataCenters.length > 0) {
			opData['dataCenter'] = this.cart.dataCenter;
		}

		const orderInfo = this.ampOpApiService.getOrderInfo();
		const catalog = this.ampOpApiService.getCartCatalog();

		this.ampOpApiService.purchase(opData)
			.pipe( catchError(error => {
				this.state = 'failed';
				this.errorMessage = error['StatusText'] || 'We could not process your order.';
				this.paymentFailure.emit();
				this.progressAmount = 0;
				this.progressMessage = '';
				this.scrollTop();
				return throwError( error );
			}))
			.subscribe((res)=> {
				if(res['Success'] === true) {
					this.state = 'success';
					this.paymentSuccess.emit();
					this.progressAmount = 0;
					this.progressMessage = '';
					this.showGoToAmpButton = 'loading'
					this.postAmpPurchaseHandling(res, orderInfo, catalog).subscribe(
						() => {
							if(this.ampOpApiService.isAuthenticated() === false) {
								this.ampOpApiService.localStorageService.removeItem('op-cart')
								let authData = {};
								if(this.ampAccountInfo?.authType === 'password') {
									authData = {
										email: user.email,
										password: user.password
									}
								} else {
									authData = {
										token: this.ampAccountInfo?.socialAuthInfo?.token
									}
								}
								this.showGoToAmpButton = 'loading'
								this.ampOpApiService.cartAuthenticate(authData).subscribe(() => {
									this.ampOpApiService.clearStoredInfo();
									this.showGoToAmpButton = 'show'
								},
								(err) => {
									this.showGoToAmpButton = 'show';
									this.ampOpApiService.clearStoredInfo();
									console.log('authentication: ', err)
								})
							} else {
								this.showGoToAmpButton = 'show'
								this.ampOpApiService.clearStoredInfo();
							}

						}
					);
				} else {
					this.state = 'failed';
					this.errorMessage = res['StatusText'] || 'We could not process your order.';
					this.paymentFailure.emit();
					this.progressAmount = 0;
					this.progressMessage = '';
					this.scrollTop();
				}
			})


	}

	public postAmpPurchaseHandling(res, orderInfo, catalog) {
		return new Observable((observer) => {
			if (res.hasOwnProperty('ReceiptId')) {
				const hostingProduct = this.cart.cart.find(e => e.type === 'hosting');
				let hostingPrice = 0.00;
				if(hostingProduct) {
					hostingPrice = hostingProduct.price_in_cents;
					if(hostingProduct.hasOwnProperty('discounts')) {
						if(hostingProduct.discounts.amount > 0) {
							hostingPrice = hostingPrice - (hostingProduct.discounts.amount *100);
						}
					}
					if(this.ampSalesDiscount > 0) {
						const ampSalesDiscount = hostingPrice * this.ampSalesDiscount;
						hostingPrice = hostingPrice - ampSalesDiscount;
					}
					hostingPrice = hostingPrice / 100;
				}
				// Account for override
				if (this.configurationService.config['googleAnalytics'].routeOverrides) {
					const override = this.configurationService.config['googleAnalytics'].routeOverrides.find(
						item => this.location.path().startsWith(item.route)
					);
					const purchasePayload = {
						orderId: res['ReceiptId'],
						total: this.cart.total,
						send_to: override?.purchaseConversion ?? '',
					}
					this.googleAnalytics.trackConversion(purchasePayload);
					if(hostingProduct) {
						const hostingPayload = {
							orderId: res['ReceiptId'] +'-H',
							total: hostingPrice,
							send_to: override?.hostingConversion ?? '',
						}
						this.googleAnalytics.trackConversion(hostingPayload);
					}
					const gEnhancedPayload = this.ampOpApiService.getGoogleEnhancedECommercePayload(res,this.cart,orderInfo, catalog);
					if(gEnhancedPayload) {
						gEnhancedPayload['send_to'] = override?.eCommerce ?? '';
						this.googleAnalytics.send(gEnhancedPayload);
						if (window.hasOwnProperty('dataLayer')) {
							window['dataLayer'].push({ecommerce: null});
							window['dataLayer'].push({
								event: 'purchase',
								ecommerce: gEnhancedPayload['ecommerce']
							});
						}
					}
				} else {
					const purchasePayload = {
						orderId: res['ReceiptId'],
						total: this.cart.total,
						send_to: this.configurationService.config.googleAnalytics.purchaseConversion,
					}
					this.googleAnalytics.trackConversion(purchasePayload);
					if(hostingProduct) {
						const hostingPayload = {
							orderId: res['ReceiptId'] +'-H',
							total: hostingPrice,
							send_to: this.configurationService.config.googleAnalytics.hostingConversion,
						}
						this.googleAnalytics.trackConversion(hostingPayload);
					}
				}
			}
			if(res.hasOwnProperty('AffiliateTracking')) {
				if(res['AffiliateTracking'].hasOwnProperty('impactRadiusv2TrackingPixel')) {
					this.loadTrackingScript(res['AffiliateTracking']['impactRadiusv2TrackingPixel'], "head")
				}
				if(res['AffiliateTracking'].hasOwnProperty('ultimateAffiliateTrackingPixel')) {
					this.loadTrackingScript(res['AffiliateTracking']['ultimateAffiliateTrackingPixel'])
				}
				if(res['AffiliateTracking'].hasOwnProperty('proAffiliatePlusTrackingPixel')) {
					this.loadTrackingScript(res['AffiliateTracking']['proAffiliatePlusTrackingPixel'])
				}
				if(res['AffiliateTracking'].hasOwnProperty('impactRadiusv2TrackingPixel')) {
					this.loadTrackingScript(res['AffiliateTracking']['impactRadiusv2TrackingPixel'])
				}
			}

			// Send Tracking Data from purchase response to Optimizely
			if(res.hasOwnProperty('TrackingData')) {
				this.handleOptimizelyEvents(res['TrackingData']);
			}
			observer.next();
			observer.complete();
		})
	}

	public changePaymentMethod() {
		this.ampPaymentMethod = this.getAmpPaymentMethod();
		if(this.ampPaymentMethod === 'PayPal') {
			this.updatePaypalStatus();
		}
	}

	public updatePaypalStatus() {
		if( this.ampPaymentMethod === 'PayPal') {
			let payPalSettings = "&disable-funding=credit,card";
			payPalSettings += "&currency=" + this.cart.currency + "&intent=authorize&commit=false";
			this.paypalInfo?.setup({
				clientId: this.configurationService.config.powerPanel.paypalClientId,
				settings: payPalSettings,
				chargeAmount: this.cart.total,
				subTotal: this.cart.total,
				salesTax: 0.00,
				createOrderUrl: this.ampOpApiService.opApiUrl + "/api/order",
				approveOrderUrl: this.ampOpApiService.opApiUrl + "/api/order",
				brand: 'imh',
				currency: this.cart.currency
			});
		} else {
			this.paypalInfo?.setPaypalStatus(false);
		}
	}

	public loadTrackingScript(data, type = "body") {
		if(data.includes('<script')) {
			const scripts = data.split('<script type="text/javascript">');
			scripts.forEach(s => {
				const script = s.replace('</script>','');
				const newScript = document.createElement('script');
				newScript.innerHTML = script;
				if(type === "head") {
					document.head.appendChild(newScript);
				} else {
					document.body.appendChild(newScript);
				}
			})
		} else {
			const newDiv = document.createElement('div');
			const newDivData = data.replace('/cgi-bin','https://secure1.inmotionhosting.com/cgi-bin');
			newDiv.innerHTML = newDivData;
			document.body.appendChild(newDiv);
		}
	}



	/**
	 * After a user is authenticated, make sure that we dont try to sell the user
	 * a new connect key when they actually wanted to upgrade.
	 *
	 * @since 1.23.0
	 */
	private displayUpgradeNotice(): void {
		this.licenseService.onReady().subscribe(() => {
			// If the user is purchasing a key service.
			if (this.cart.hasKeyService() && !this.cart.hasProductType('key')) {
				const licenses = this.licenseService.getLicenses();

				// User has multiple keys.
				if (licenses['connect_keys'].length > 1) {
					this.dialog.open(UpgradeDialogComponent, {
						width: '400px',
					});
				}
			}
		});
	}

	private displayAddNewCardDialog() {
		const ref = this.appService.openDialog(BillingAddressComponent,
			{ accountInfo: this.ampAccountInfo, ampPayment: this.ampPayment }
		);

		ref.componentInstance.addCardSuccess.subscribe(() => {
			this.ampOpApiService.getAccountInfo().subscribe( (accountInfo: any) => {
				ref.close(false);
				this.ampPayment.accountInfo = accountInfo;
				this.ampPayment.cardId = ref.componentInstance.cardId;
				this.ampPayment.selectPaymentMethod();
				this.ampAccountInfo.contactInputs = accountInfo;
				ref.componentInstance.status = 'success';
				this.ampPayment.getExistingCards();

			});

		});
	}

	/**
	 * If on sign in, display an upgrade notice if needed.
	 *
	 * @since 1.23.0
	 */
	private _setupAuthEvent(): void {
		// Only do this on XOP.
		if (this.contactInputs?.emailInput) {
			this.contactInputs.emailInput.onLogin.subscribe(() => {
				this.displayUpgradeNotice();
			});

			if (this.authService.isLoggedIn()) {
				this.displayUpgradeNotice();
			}
		}
	}

	public maybeUpdate() {
		const address = this.getInfo();
		this.errorMessage = '';

		if (this.changeCard) {
			this.updateState = 'submitted';

			// Send update when use CC.
			this.recurlyCC
				.getToken(address)
				.then((token) => {
					if ('vantiv' === token['type']) {
						this.updateBilling({ address, vantiv: token });
					} else {
						this.updateBilling({ address, card: token['id'] });
					}
				})
				.catch(() => (this.updateState = 'failed'));
		} else {
			// Send Update for address fields only.
			this.updateBilling({ address });
		}
	}

	public getInfo() {
		const address = this.contactInputs.model.getData();
		address['zip'] = address.postal_code;

		return address;
	}

	public hasCard() {
		if(this.checkoutMode === 'amp' || this.checkoutMode === 'inline') {
			return false;
		}
		return !!this.paymentMethod.card_type;
	}

	public fetchBillingInformation() {
		if(this.checkoutMode === 'default' || this.checkoutMode === 'inline') {
			this.fetchState = 'loading';
			const accountId =
				this.organizationId;

			this.apiService
				.get(`/v1/wpcr/accounts/${accountId}/payments/method`, {})
				.subscribe({
					next: (paymentMethod: any) => {
						// Should account for:
						// +17572222222
						// +1.7572222222
						const valid164 = /^\+[1-9]\.?\d{10,14}$/;
						this.phoneIsValid164 = valid164.test(paymentMethod.phone);
						if (!this.phoneIsValid164) {
							paymentMethod.phone = '';
						}

						this.loadForm(paymentMethod);

						if (this.paymentMethod.last_four) {
							this.selectedMethod = this.paymentMethod.last_four;
						} else {
							this.selectedMethod = 'new';
						}

						if (!this.phoneIsValid164) {
							this.selectedMethod = 'new';
						}

						if (!paymentMethod.zip) {
							this.changeCard = true;
						}

						this.fetchState = 'success';
						this.paymentReady.emit();
					},
					error: () => {
						this.fetchState = 'failed';
					},
				});
		}

		if(this.checkoutMode === 'amp') {
			this.ampOpApiService.getAccountInfo().subscribe({
				next: (accountInfo: any) => {
					this.loadForm(accountInfo.user);
					if(accountInfo.checkAvailable) {
						this.ampAccountInfo.checkAvailable = true;
					} else {
						this.ampAccountInfo.checkAvailable = false;
					}
					this.ampPayment.accountInfo = accountInfo;
					this.ampPayment.getExistingCards();
					this.fetchState = 'success';
					this.ampAccountInfo.contactInputs = accountInfo;
					this.paymentReady.emit();
				},
				error: () => {
					this.fetchState = 'failed';
				}
			})
		}
	}

	public identifyLogRocket() {
		const info = this.contactInputs.model.getData();
		const email = this.ampAccountInfo.validEmail();
		const data = {};
		data['name'] = info.first_name + " " + info.last_name;
		data['email'] = email;
		data['company'] = info.company_name;
		this.logrocketService.configureAmpOpLogrocket(data);
	}

	public updateBilling(params) {
		this.updateState = 'submitted';
		const accountId =
			this.organizationId ||
			this.authService.jwtHelper.getTokenVal('user_id');

		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});

		const url = this.apiService.formatter.getUrl(
			`/v1/wpcr/accounts/${accountId}/payments/method`
		);

		this.apiService.http.post(url, params, { headers }).subscribe({
			next: (newInfo) => {
				this.loadForm(newInfo);
				this.updateState = 'success';
				this.update.emit();
			},
			error: (response) => {
				this.errorMessage = this.apiService.getPublicError(response);
				this.updateState = 'failed';
			},
		});
	}

	/**
	 * Loads billing information into the payment form
	 *
	 * This method populates the payment form with billing information received from the API.
	 * It handles both AMP and default checkout modes, setting appropriate form values
	 * based on the payment method and country information.
	 *
	 * @param {Object} apiBillingInfo - The billing information object from the API
	 * @param {string} [apiBillingInfo.zip] - Postal/ZIP code
	 * @param {string} [apiBillingInfo.last_four] - Last 4 digits of credit card
	 * @param {string} [apiBillingInfo.phone] - Phone number
	 * @param {string} [apiBillingInfo.country] - Country code
	 * @param {string} [apiBillingInfo.countryCode] - Country code for state lookup
	 * @param {string} [apiBillingInfo.state] - State/province code
	 * @param {string} [apiBillingInfo.card_type] - Type of credit card
	 * @param {Object} [apiBillingInfo.account] - Account information
	 * @param {string} [apiBillingInfo.account.billing_email] - Account billing email
	 * @param {string} [apiBillingInfo.account.primary_email] - Account primary email
	 *
	 * @private
	 */
	private loadForm(apiBillingInfo) {
		this.paymentMethod = apiBillingInfo;


		this.paymentMethod.postal_code = this.paymentMethod.zip;

		if (this.paymentMethod.last_four || this.checkoutMode === 'amp') {
			this.contactInputs.model.update(this.paymentMethod);
		}
		if (this.contactInputs.model.phone) {
			this.contactInputs.phoneNumber.iti.setNumber(this.contactInputs.model.phone);
		}
		if (this.checkoutMode === 'amp'){
			if(this.paymentMethod.country !== 'US') {
				this.contactInputs.autofillControls['country'].control.setValue(
					this.paymentMethod.country
				);
				this.handleCountryInfo(this.paymentMethod.countryCode)
					.subscribe(resp => {
						const countryState = resp['States']?.find(e => e.Code === this.paymentMethod.state) || null;
						if (countryState) {
							this.contactInputs.autofillControls['state'].control.setValue(
								countryState.FullName
							);
						}
					})
			}
		} else {
			if (!this.paymentMethod.card_type) {
				this.contactInputs.model.clear();
				if (this.useTeamSelector) {
					this.organizationEmail = this.paymentMethod.account?.billing_email ||
						this.paymentMethod.account?.primary_email;
				}
			}
			this.contactInputs.autofillControls['state'].control.setValue(
				this.paymentMethod.state
			);
		}
	}

	public getDiscountObject() {
		if (this.checkoutMode === 'amp') {
			const ampDiscountObject = {
				type:"dollars",
				amount: 0.00
			}
			this.packages.forEach((p) => {
				let newDiscount = 0;
				if(p.hasOwnProperty('discounts') && p.discounts !== undefined) {
					let doNotAdd = false;
					if(p.info.hasOwnProperty('AddonIncluded')) {
						if(p.info['AddonIncluded'] === true) {
							doNotAdd = true;
						}
					}
					newDiscount = parseFloat(p.discounts.amount) * 100;
					if(newDiscount > 0 && !doNotAdd) {
						ampDiscountObject.amount += newDiscount;
					}
				}
				if(this.ampSalesDiscount > 0 && p.type === 'hosting') {
					const newPrice = p.price_in_cents - newDiscount;
					const salesDiscount = newPrice * this.ampSalesDiscount;
					ampDiscountObject.amount += salesDiscount;
				}
			})
			return JSON.stringify(ampDiscountObject)
		}

		if (this.discountObject) {
			return this.discountObject;
		}

	}

	public getCouponData() {
		if (this.couponData) {
			this.hideGuarantee = true;
			return this.couponData;
		}
	}

	public isTrial() {
		if (this.getCouponData()) {
			return this.getCouponData().coupon_code.match(/-trial$/) ? true : false;
		}
		return false;
	}

	skipPurchase() {
		this.apiService.post( '/v1/guide/override', {
			coupon_code: this.couponData?.coupon_code
		} ).subscribe({
			next: (res: any) => {
				if ( res.login_token ) {
					this.router.navigate(
						[],
						{
						  relativeTo: this.route,
						  queryParams: { return: '/projects' },
						  queryParamsHandling: 'merge'
						}
					).then(()=>{
						this.authService.setLogin( res.login_token );
					});
				}
			},
			error: (err:any) => {
				console.error(err);
			},
		});
	}

	hideContactform() {
		if(this.checkoutMode === 'default' || this.checkoutMode === 'inline') {
			return this.selectedMethod !== 'new';
		}
		if(this.checkoutMode === 'amp') {
			return this.selectedMethod !== 'new';
		}
	}

	hideNewPaymentMethod() {
		if(this.checkoutMode === 'default' || this.checkoutMode === 'inline') {
			return this.selectedMethod !== 'new';
		}
		if(this.checkoutMode === 'amp') {
			if(this.selectedMethod === 'new') {
				return false;
			} else {
				return !this.ampAccountInfo?.isAuthenticated;
			}
		}
	}

	public getAmpPaymentMethod() {
		let paymentMethod = 'Credit Card';
		if(this.ampPayment?.paymentMethod === 'Check') {
			paymentMethod = 'Check';
		}
		if(this.ampPayment?.paymentMethod === 'PayPal') {
			paymentMethod = 'PayPal';
		}
		return paymentMethod;
	}

	public ampOrderValid() {
		let submitValid = this.accountInfoValid() && this.form.valid;
		if(submitValid === true) {
			if(this.ampPayment?.cardId === 'new') {
				submitValid = this.ampPayment?.creditcard?.isValid();
			}
		}

		return submitValid === true && this.termsAgree === true;
	}

	public handlePaypalResult(data) {
		if(data['PayPalAuthorized'] === true) {
			this.ampPayment.paypalId = data['PaymentId'];
			if(this.captchaRequired) {
				this.captchaRequired = false;
			}
			this.submit();
		} else {
			this.state = 'failed';
			this.errorMessage = 'PayPal Transaction failed.';
			this.paymentFailure.emit();
			this.scrollTop();
			return throwError( 'PayPal Transaction failed.');
		}
	}

	public handleCountryInfo(code) {
		return new Observable((observer) => {
			this.ampOpApiService.countryLookup(code)
				.subscribe(
					(resp) => {
						if(resp.hasOwnProperty('VAT')) {
							this.vatTax = resp['VAT']
						} else {
							this.vatTax = false
						}
						observer.next(resp)
						observer.complete();
					},
					(err) => {
						observer.error(err);
					}
				)
		})
	}

	public ampEmailChecked() {
		const country = this.contactInputs?.model.country;
		this.ampCheckAvailability(country);
		if(this.ampAccountInfo.contactEmail !== this.ampOpApiService.emailAddress && this.packages) {
			this.ampOpApiService.saveCart(this.packages, {email: this.ampAccountInfo.contactEmail})
		}
		if(this.ampAccountInfo.socialAuthInfo.lastName !== '') {
			this.contactInputs.lastName.control.setValue(this.ampAccountInfo.socialAuthInfo.lastName);
		}
		if(this.ampAccountInfo.socialAuthInfo.firstName !== '') {
			this.contactInputs.firstName.control.setValue(this.ampAccountInfo.socialAuthInfo.firstName);
		}
	}
	
	public ampCheckAvailability(country) {
		if(country !== 'US') {
			this.ampPayment.allowCheck = false;
		} else {
			if(this.ampOpApiService.isAuthenticated()) {
				this.ampPayment.allowCheck = this.ampAccountInfo.checkAvailable;
			} else {
				const allowCheck = this.publicNetwork === false ? true : this.ampAccountInfo.allowCheck;
				this.ampAccountInfo.allowCheck = allowCheck;
				this.ampPayment.allowCheck = allowCheck;
			}
		}
	}

	public accountTypeChanged() {
		this.accountType = this.ampAccountInfo?.accountType;
		this.selectedMethod = this.accountType;
		if(this.ampAccountInfo.socialAuthInfo.lastName === '') {
			this.contactInputs.lastName.control.setValue('');
		}
		if(this.ampAccountInfo.socialAuthInfo.firstName === '') {
			this.contactInputs.firstName.control.setValue('');
		}
	}

	public ampLogin() {
		if(this.ampOpApiService.isAuthenticated()) {
			this.readOnlyForm = true;
			this.fetchBillingInformation();
		}
	}

	public ampLogout() {
		this.contactInputs?.model.clear();
		this.contactInputs?.autofillControls['state'].control.setValue('');
		this.contactInputs?.autofillControls['country'].control.setValue('US');
		this.contactInputs?.phoneNumber.iti.setNumber('');
		this.ampPayment?.clearInfo();
		this.readOnlyForm = false;
	}

	public internalOnlyChanged(event) {
		this.salespersonId = event.SalesPersonId;
		this.salespersonId2 = event.SalesPersonId2;
		this.customerType = event.CustomerType;
		this.ampOpApiService.setInternalOnlyInfo(event);
	}

	public accountInfoValid() {
		let valid = false;
		this.accountType = this.ampAccountInfo?.accountType;
		const isAuthenticated = this.ampOpApiService.isAuthenticated()
		const contactEmail = this.ampAccountInfo?.validEmail();
		let authValidation = false;
		if(this.ampAccountInfo?.authType === 'password') {
			authValidation = ![false,undefined].includes(this.ampAccountInfo?.newPassword?.isValid)
				&& (this.ampAccountInfo.existingEmailPrevention === false
				|| (this.ampAccountInfo?.emailExists === false && this.ampAccountInfo.existingEmailPrevention === true));
		} else {
			authValidation = this.ampAccountInfo?.authType === 'social' && this.ampAccountInfo?.providerState === 'complete';
		}
		if(this.accountType === 'existing' && isAuthenticated === true){
			valid = true;
		}
		if(this.accountType === 'new' && contactEmail !== '' && authValidation && this.contactInputs.isFormValid()) {
			valid = true;
		}
		return valid;
	}

	public contactInfoValid() {
		let valid = false;
		if(
			this.contactInputs?.isFormValid() &&
			this.contactInputs?.model.firstName.trim().length > 0 &&
			this.contactInputs?.model.lastName.trim().length > 0 &&
			this.contactInputs?.model.phone.length > 0 &&
			this.ampAccountInfo?.validEmail() !== '' &&
			this.contactInputs?.model.address1.length > 0 &&
			this.contactInputs?.model.city.length > 0 &&
			this.contactInputs?.isValidProvince() &&
			this.contactInputs?.model.country.length > 0 &&
			this.contactInputs?.model.postalCode.length > 0
		) {
			valid = true;
		}
		return valid;
	}

	public setCartTotal(total) {
		this.ampCartTotal = total;
		if(this.getAmpPaymentMethod() === 'PayPal') {
			this.updatePaypalStatus();
		}
	}

	public getAmpCartInfo() {
		const ampCartInfo = this.ampOpApiService.getStoredCart();
		return new Observable((observer) => {
			if(ampCartInfo.hasOwnProperty('AvailableProducts')) {
				observer.next(ampCartInfo);
				observer.complete();
			} else {
				if(ampCartInfo.hasOwnProperty('CustomerSessionKey')) {
					this.ampOpApiService.initializeCart(ampCartInfo.CustomerSessionKey)
						.subscribe(catalog => {
							observer.next(catalog);
							observer.complete();
						}
					)
				}
			}
		})
	}

	public hasFreeEligibleDomain() {
		return this.packages?.filter(p => p.type === 'domain' && p.freeEligible === true).length > 0;
	}

	public setAdditionalAmpData() {
		this.getAmpCartInfo().subscribe(ampCartInfo => {
			// Set sales discount
			if (ampCartInfo.hasOwnProperty('SalesClosingDiscountPercent')) {
				this.ampSalesDiscount = ampCartInfo['SalesClosingDiscountPercent'];
			}
			// Set data centers
			if(ampCartInfo.hasOwnProperty('AvailableProducts') && this.dataCenters.length === 0) {
				this.setAmpDataCenters(ampCartInfo);
			}
		});
	}

	public setDataCenters() {
		this.packages.forEach((product) => {
			if (product.options?.data_center) {
				this.dataCenter = this.dataCenterMap[product.options.data_center];
				this.dataCenterChanged.emit(product.options.data_center);
			}
		});
	}

	/**
	 * Sets up data center options for AMP checkout based on catalog information
	 *
	 * This method processes the catalog data to determine available data centers and set defaults.
	 * It handles:
	 * 1. Extracting data center information from catalog items
	 * 2. Setting the current data center based on hosting plan
	 * 3. Building the list of available data centers if selection is allowed
	 * 4. Setting default data center values
	 *
	 * @param {Object} catalog - The catalog object containing product and data center information
	 * @param {Object} [catalog.AvailableProducts] - Available products in the catalog
	 * @param {Object} [catalog.Items] - Current items in the cart
	 *
	 * @private
	 */
	private setAmpDataCenters(catalog) {
		if (catalog.hasOwnProperty('AvailableProducts') && this.dataCenters.length === 0 && !this.ampDomainOnly) {
			const catalogItems = catalog?.Items || {};
			const items = Object.keys(catalogItems).map((key) => catalogItems[key]) || [];
			const hostingPlan = items.find(e => e.hasOwnProperty('DataCenter'));
			if(hostingPlan) {
				this.dataCenter = parseInt(hostingPlan['DataCenter'],10);
			}
			const aProducts = Object.keys(catalog['AvailableProducts']).map((key) => catalog['AvailableProducts'][key]) || [];
			const productId = items.length > 0 ? items[0]['ProductId'] : null;
			const hostingCatDetails = aProducts.find(e => e !== null && e.Id === productId);
			if (hostingCatDetails && hostingCatDetails.hasOwnProperty('DataCenters')) {
				if (hostingCatDetails['DataCenters'].CanSelectDataCenter === true) {
					this.dataCenters = [];
					Object.keys(hostingCatDetails['DataCenters']).forEach((i: any) => {
						if (!isNaN(i)) {
							this.dataCenters.push(hostingCatDetails['DataCenters'][i])
						}
					})
					if(hostingPlan) {
						this.dataCenter = parseInt(hostingPlan['DataCenter'],10);
					} else {
						if (hostingCatDetails['DataCenters'].DefaultDataCenter) {
							if (hostingCatDetails['DataCenters'].DefaultDataCenter !== "lookup") {
								this.dataCenter = hostingCatDetails['DataCenters'].DefaultDataCenter.Id;
							}
						}
					}

				}
			}
		}
	}
}
