<ng-container *ngIf="'success' === this.state">
	<mat-sidenav-container *ngIf="this.projectService?.hasEnvironments()">
		<mat-sidenav id="side-nav" class="advanced-layout" [class.sidebar-disabled]="
	!this.authService.isImpersonating()	&&
	(this.projectService.environment?.fields?.environment_usage === 'unknown' ||
		!this.projectService.hasEnvironmentControl())" [mode]="this.sidenavMode" #sidenav>
			<mat-nav-list id="side-nav-list ctw-p-0">
				<div class="menu-header">
					<img class="site-image"
						*ngIf="this.projectService.getScreenshot(this.projectService.project, this.projectService.environment)"
						[centralCachedSrc]="this.projectService.getScreenshot(this.projectService.project, this.projectService.environment)"
						(click)="this.updateScreenshot()" />
				</div>
				<ng-container *ngIf="this.projectService.environment">
					<ng-container>
						<mat-list-item (click)="this.wpRestService.login(this.projectService.environment)"
							[class.ctw-opacity-50]="!this.projectService?.client"
							[disabled]="!this.projectService?.client">
							<mat-icon mat-list-icon class="mat-primary">
								<svg fill="#aaaaaa" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
									xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px"
									viewBox="0 0 96.24 96.24" xml:space="preserve" stroke="#ffffff"
									class="wordpress-icon" [class.live]="this.projectService?.client">
									<g id="SVGRepo_bgCarrier" stroke-width="0" />
									<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
									<g id="SVGRepo_iconCarrier">
										<g>
											<path
												d="M48.122,0C21.587,0,0.001,21.585,0.001,48.118c0,26.535,21.587,48.122,48.12,48.122c26.532,0,48.117-21.587,48.117-48.122 C96.239,21.586,74.654,0,48.122,0z M4.857,48.118c0-6.271,1.345-12.227,3.746-17.606l20.638,56.544 C14.81,80.042,4.857,65.243,4.857,48.118z M48.122,91.385c-4.247,0-8.346-0.623-12.222-1.763L48.88,51.903l13.301,36.433 c0.086,0.215,0.191,0.411,0.308,0.596C57.992,90.514,53.16,91.385,48.122,91.385z M54.083,27.834 c2.604-0.137,4.953-0.412,4.953-0.412c2.33-0.276,2.057-3.701-0.277-3.564c0,0-7.007,0.549-11.532,0.549 c-4.25,0-11.396-0.549-11.396-0.549c-2.332-0.137-2.604,3.427-0.273,3.564c0,0,2.208,0.275,4.537,0.412l6.74,18.469l-9.468,28.395 L21.615,27.835c2.608-0.136,4.952-0.412,4.952-0.412c2.33-0.275,2.055-3.702-0.278-3.562c0,0-7.004,0.549-11.53,0.549 c-0.813,0-1.77-0.021-2.784-0.052C19.709,12.611,33.008,4.856,48.122,4.856c11.265,0,21.519,4.306,29.215,11.357 c-0.187-0.01-0.368-0.035-0.562-0.035c-4.248,0-7.264,3.702-7.264,7.679c0,3.564,2.055,6.582,4.248,10.146 c1.647,2.882,3.567,6.585,3.567,11.932c0,3.704-1.422,8-3.293,13.986l-4.315,14.421L54.083,27.834z M69.871,85.516l13.215-38.208 c2.471-6.171,3.29-11.106,3.29-15.497c0-1.591-0.104-3.07-0.292-4.449c3.38,6.163,5.303,13.236,5.301,20.758 C91.384,64.08,82.732,78.016,69.871,85.516z" />
										</g>
									</g>
								</svg>
							</mat-icon>
							<span class="mat-list-text">
								<div class="">{{ !this.projectService?.client ? 'Connecting...' : 'Admin' }}</div>
							</span>
							<mat-icon class="open-in-new">open_in_new</mat-icon>
						</mat-list-item>
						<mat-list-item routerLinkActive="router-link-active"
							[routerLinkActiveOptions]="{ exact: false }" [routerLink]="[
						'/projects',
						this.projectService?.project?.id,
						this.configService?.hasFeature('IMC-683') ? 'overview' : 'site'
					]">
							<mat-icon mat-list-icon class="mat-primary">info</mat-icon>
							<span class="mat-list-text">
								<div class="">Overview</div>
							</span>
							<mat-icon class="arrow"
								*ngIf="this.projectService.hasEnvironmentControl()">keyboard_arrow_right</mat-icon>
						</mat-list-item>
						<mat-list-item [disabled]="this.projectService?.environmentState === 'pending'" *ngIf="
						this.projectService?.environment?.fields
							.has_publish_setup_user
					" [routerLink]="[
						'/projects',
						this.projectService?.project?.id,
						'configure'
					]" routerLinkActive="router-link-active"
							[class.ctw-opacity-50]="this.projectService?.environmentState === 'pending'">
							<mat-icon mat-list-icon class="">build_circle</mat-icon>
							<span matLine>Configure</span>
							<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
						</mat-list-item>
						<mat-list-item routerLinkActive="router-link-active" [disabled]="!this.projectService?.client"
							[routerLink]="['/projects', this.projectService?.project?.id, 'optimize']"
							[class.ctw-opacity-50]="!this.projectService?.client"
							*ngIf="this.projectService?.environment?.fields.environment_type === 'vps'">
							<mat-icon mat-list-icon class="">speed</mat-icon>
							<span matLine>Optimize</span>
							<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
						</mat-list-item>
						<mat-list-item [disabled]="this.projectService?.environmentState === 'pending'"
							routerLinkActive="router-link-active"
							[routerLink]="['/projects', this.projectService?.project?.id, 'recover']"
							*ngIf="this.projectService?.environment?.fields.environment_type === 'vps'"
							[class.ctw-opacity-50]="this.projectService?.environmentState === 'pending'">
							<mat-icon mat-list-icon class="">settings_backup_restore</mat-icon>
							<span matLine>Recover</span>
							<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
						</mat-list-item>
						<mat-list-item [disabled]="false && this.projectService?.environmentState === 'pending'"
							routerLinkActive="router-link-active"
							[routerLink]="['/projects', this.projectService?.project?.id, 'secure']"
							*ngIf="this.configService.hasFeature('monarx') && this.projectService?.environment?.fields.environment_type === 'vps'"
							[class.ctw-opacity-50]="this.projectService?.environmentState === 'pending'">
							<mat-icon mat-list-icon class="">shield</mat-icon>
							<span matLine>Secure</span>
							<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
						</mat-list-item>
						<mat-divider></mat-divider>
						<a mat-list-item
							href="https://www.inmotionhosting.com/support/product-guides/wordpress-hosting/central/"
							target="_blank" class="mat-button-base">
							<mat-icon mat-list-icon>menu_book</mat-icon>
							<span matLine>Documentation</span>
							<mat-icon class="open-in-new">open_in_new</mat-icon>
						</a>
						<mat-list-item [disabled]="this.projectService?.environmentState === 'pending'" *ngIf="
						this.projectService?.environment?.fields
							.has_publish_setup_user && this.configService?.hasFeature('managedHosting') &&
							this.projectService?.environment?.fields.environment_type === 'vps'
					" [routerLink]="[
						'/projects',
						this.projectService?.project?.id,
						'services'
					]" routerLinkActive="router-link-active"
							[class.ctw-opacity-50]="this.projectService?.environmentState === 'pending'">
							<mat-icon mat-list-icon class="">support_agent</mat-icon>
							<span matLine>PRO Services</span>
							<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
						</mat-list-item>
						<mat-list-item *ngIf="this.authService?.isImpersonating()" [routerLink]="[
						'/projects',
						this.projectService?.project?.id,
						'agent'
					]" routerLinkActive="router-link-active" class="ctw-bg-black">
							<mat-icon mat-list-icon class="ctw-text-red-700">admin_panel_settings</mat-icon>
							<span matLine class="ctw-text-white">Agent Controls</span>
							<mat-icon class="arrow">keyboard_arrow_right</mat-icon>
						</mat-list-item>

						<mat-list-item *ngIf="this.authService?.isImpersonating()"
							(click)="quickCopy()"
							routerLinkActive="router-link-active" class="ctw-bg-black">
							<mat-icon mat-list-icon class="ctw-text-red-700">attach_file</mat-icon>
							<span matLine class="ctw-text-white">Quick Copy</span>
						</mat-list-item>
					</ng-container>
				</ng-container>
			</mat-nav-list>
		</mat-sidenav>
		<mat-sidenav-content>
			<central-header *ngIf="this.projectService?.project?.id && (
				!this.hasRoute('/projects/' + this.projectService?.project?.id) &&
				!this.hasRoute('/projects/' + this.projectService?.project?.id + '/add', true) &&
				!this.hasRoute('/projects/' + this.projectService?.project?.id + '/resize', true)
			)" [title]="this.projectService?.environment?.label" subTitle=""
				[actions]="this.projectService.hasEnvironmentControl() ? 'environment' : ''"
				[actionsEnabled]="this.projectService?.environment?.fields.environment_type !== 'vps' ||
		((this.ansibleService.state === 'complete' || this.ansibleService.state === 'pending' || this.ansibleService.state === 'playbook-overridden') && this.projectService.serverStatus && this.projectService.serverStatus['status'] === 'running') || (this.authService.isImpersonating() && this.projectService.serverStatus && this.projectService.serverStatus['status'] === 'running')"
				(action)="this.header($event)" [service]="this.projectService" [data]=""
				[notice]="this.ansibleService.state && this.ansibleService.state !== 'complete' && this.ansibleService.state !== 'pending' && this.ansibleService.state !== 'playbook-overridden' ? 'playbook-running' : false"
				[status]="this.playbookStatus || this.state"
				[class.ctw-overflow-hidden]="this.projectService?.environment?.fields?.environment_type !== 'vps'">
			</central-header>

			<central-site-setup *ngIf="!this.authService?.isImpersonating() &&
		this.projectService?.environment?.fields?.environment_usage === 'unknown' &&
		!this.hasRoute('/projects/' + this.projectService?.project?.id) &&
		!this.hasRoute('/projects/' + this.projectService?.project?.id + '/add', true) &&
		!this.hasRoute('/projects/' + this.projectService?.project?.id + '/resize', true) &&
		!this.hasRoute('/projects/' + this.projectService?.project?.id + '/configure')"
				[environment]="this.projectService?.environment">
			</central-site-setup>

			<ng-container *ngIf="
			this.authService?.isImpersonating() ||
			this.projectService?.environment?.fields?.environment_usage !== 'unknown' ||
			this.hasRoute('/projects/' + this.projectService?.project?.id) ||
			this.hasRoute('/projects/' + this.projectService?.project?.id + '/add', true) ||
			this.hasRoute('/projects/' + this.projectService?.project?.id + '/resize', true) ||
			this.hasRoute('/projects/' + this.projectService?.project?.id + '/configure')
		">

				<!-- Member (Read ONLY) View -->
				<ng-container *ngIf="
				!this.projectService.hasEnvironmentControl() &&
				!this.isProjectRoute && this.projectAccess
				else hasControl
			">
					<div class="no-access">
						<img class="logo"
							src="https://inmotionhosting.github.io/static-assets/illustrations/small/ssh-key.svg"
							alt="" />
						<div class="ctw-text-center ctw-mb-5">
							<h2 class="ctw-capitalize">View Only Access</h2>
						</div>
						<p>
							You do not have access to this environment but you can
							still log in to WordPress site manually if you have
							credentials.
						</p>
						<div class="ctw-text-right ctw-mt-10">
							<a href="{{
							this.projectService.environment?.fields
								.site_url
						}}/wp-admin" mat-stroked-button color="primary" target="_blank">
								<mat-icon class="ctw-mr-2 ctw-mb-1">{{ this.projectService.project.fields.project_type
									}}</mat-icon>
								<span>Login</span>
							</a>
							<a href="{{
							this.projectService.environment?.fields.site_url
						}}" mat-raised-button color="primary" target="_blank" class="ctw-ml-3">
								<span>View Site</span>
							</a>
						</div>
					</div>
				</ng-container>

				<!-- Editor / Manager View -->
				<ng-template #hasControl>
					<ng-container *ngIf="status === 'loading'">
						<mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
					</ng-container>
					<router-outlet></router-outlet>
				</ng-template>

			</ng-container>
		</mat-sidenav-content>
	</mat-sidenav-container>
</ng-container>
