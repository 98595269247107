import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
	selector: 'central-monarx-plugin-view',
	templateUrl: './monarx-plugin-view.component.html',
	styleUrls: ['./monarx-plugin-view.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonarxPluginViewComponent {
	@Input() monarxUrl: string;

	constructor(
		public sanitizer: DomSanitizer
	) { }

	sanitizeUrl(url): SafeHtml {
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}
}
