import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ApiService, ProfileService } from '@central/ng-shared';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { Formatter } from '../../../../domain/management/dns/dns-zone-editor.formatter';
import { ProjectService } from '../../../project.service';
import { PollingService } from '@central/ng-shared';
import { AppService } from '../../../../app.service';
import { DialogComponent } from '../../../../shared/dialog/dialog.component';
import { AnsibleService } from '../../services/ansible.service';

@Component({
	selector: 'central-site-url',
	templateUrl: './site-url.component.html',
	styleUrls: ['./site-url.component.scss'],
})
export class SiteUrlComponent implements OnInit, OnDestroy {

	public available = true;
	public state = 'loading';

	public domain: string;
	public domainState = 'needs-dns';
	public domainCheckState = 'pending';
	public forceContinueState = 'pending';
	public failedDns = false;
	public currentDomain: '';
	public usedDomains: string[] = [];
	public usedState = 'loading';
	public hostname: '';
    public dnsView = 'change-domain';
	public dnsEntry: string;
	public status: string;
	public invalid: boolean;

	private subscriptions = [];

	constructor(
		public apiService: ApiService,
		public appService: AppService,
		public projectService: ProjectService,
		public pollingService: PollingService,
		public clipboard: Clipboard,
		public snackbar: MatSnackBar,
		private profileService: ProfileService,
		private ansibleService: AnsibleService,
	) {}

	ngOnInit(): void {
		this.subscriptions.push(
			this.profileService.onReady().subscribe(() => {
				this.subscriptions.push(
					this.projectService.getServerInfo.subscribe((msg) => {
						// TODO: Disallow functionality if container is stopped.
						if (this.projectService.environmentState !== 'pending') {
							this.subscriptions.push(
								this.ansibleService.playbookStatus.subscribe((data) => {
									if (data === 'complete') {
										this.checkStatus();
										// Loaded info needed for view.
										this.available = true;
										this.fetchProjects();
									} else {
										if (data === 'pending') {
											this.checkStatus();
											this.available = true;
										} else {
											// Playbook is running or playbooks are disabled.
											this.state = data === 'playbook-overridden' ? 'disabled' : 'unavailable';
											this.available = false;
										}
									}
								})
							)
						}
					})
				)
			})
		)
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe());
	}

	public isReady() {
		this.status = 'ready';
	}

	public copy() {
		this.clipboard.copy(this.dnsEntry.trim());
		this.snackbar.open('Copied DNS Record to Clipboard!', '', {
			duration: 4000,
		});
	}

	public fetchProjects() {
		const allEnvs = [];
		this.appService.getProjects().forEach((project: any) => {
			if (project.children) {
				project.children.forEach((child) => {
					if (project.children.hasOwnProperty(child)) {
						allEnvs.push(child);
					}
				})
			}
		})
		this.hostname = this.projectService.environment.fields.hostname;
		this.usedDomains = allEnvs
			.filter((env) => env.fields.domain)
			.map((env) => env.fields.domain);
		this.usedState = 'success';
	}

	public hasDomain() {
		return this.projectService.environment.fields.domain;
	}

	public checkStatus() {
		// Reset environment domain_info if user cancels change process
		// + Data fix for older accounts missing domain_info fields
		if (!this.projectService.environment.fields.domain_info ||
			(this.projectService.environment.fields.domain_info?.domain?.baseDomain &&
				!this.projectService.environment.fields.site_url.includes(this.projectService.environment.fields.domain_info.domain.baseDomain) &&
				(this.projectService.environment.fields.playbook_running === 'complete' ||
					!this.projectService.environment.fields.playbook_running))) {
						if (this.projectService.environment.fields.domain_info) {
							// If we already have domain info, set the status to update the domain info, and move on
							this.setStatus(this.projectService.environment);
							return;
						}
				this.apiService.http
				.post(
					this.apiService.formatter.getUrl(
						`/v1/environments/${this.projectService.environment.id}/url-validation`
					),
					{fqdn: this.projectService.environment.fields.site_url.split('/')[2]},
					{
						headers: this.apiService.getHeaders({
							contentType: 'application/json',
						}),
					}
				)
				.subscribe({
					next: (response: any) => {
						this.projectService.reloadProject(false).subscribe(() => {
							this.setStatus(this.projectService.environment);
						})
					},
					error: (response) => {},
				});
		} else {
			this.setStatus(this.projectService.environment);
		}
	}

	public setStatus(environment) {
		this.currentDomain = this.projectService.environment.fields.domain_info?.domain?.fqdn ??
			this.projectService.environment.fields.site_url.split('/')[2];

		this.domainState = this.projectService.environment.fields.domain_info?.state ?? 'needs-dns';
		this.domainState = this.projectService.environment.fields.domain_info?.info?.is_available ?
			'needs-registration' : this.domainState;
		this.domainState =
			this.projectService.environment.fields.domain_info?.domain?.baseDomain?.includes('.inmotionhosting.com') || this.projectService.environment.fields.site_url.includes('.inmotionhosting.com') ?
				'ready' : this.domainState;

		if (this.domainState === 'needs-dns') {
			this.dnsEntry = `     ${
				this.projectService.environment.fields.domain
			}.     A     ${this.getIpAddress()}`;
		}

		// Update Project Service fields.
		this.projectService.environment.fields = {
			...this.projectService.environment.fields,
			...environment.fields,
		};
		this.state = 'success';
	}

	public getIpAddress() {
		return this.projectService.environment.fields.ipAddress;
	}

	public checkDomainStatus(changeDomain = {}) {
		this.domainCheckState = 'submitted';

		let data = {};
		if (changeDomain['fqdn']) {
			data = changeDomain;
			this.domain = changeDomain['fqdn'];
		}

		this.apiService.http
			.post(
				this.apiService.formatter.getUrl(
					`/v1/environments/${this.projectService.environment.id}/url-validation`
				),
				data,
				{
					headers: this.apiService.getHeaders({
						contentType: 'application/json',
					}),
				}
			)
			.subscribe({
				next: (response: any) => {
					this.domainState = 'ready';
					this.getConfirmation();

					if (
						this.domainState === 'needs-dns' &&
						this.forceContinueState === 'pending'
					) {
						this.dnsEntry = `${
							this.domain
						}.     A     ${this.getIpAddress()}`;
					}
				},
				error: (response) => {
					this.domainCheckState = 'pending';
					this.invalid = response?.error?.errors[0]?.name === 'invalid_domain';
				},
			});
	}

	public getUrl() {
		return this.domain;
	}

	public getConfirmation() {
		const ref = this.appService.openDialog(DialogComponent, {
			title: 'Are You Sure?',
			content: `We have everything we need to make your site live at <strong>${this.domain}</strong>. Afterwards, you can enable Auto SSL when ready.`,
			html: true,
			confirmButton: 'Yes, Change My Site URL',
			confirmButtonClass: 'mat-raised-button mat-warn',
			cancelButton: 'Cancel',
		});

		ref.afterClosed().subscribe((confirmed) => {
			if (confirmed) {
				this.applyUrl();
			} else {
				this.checkStatus();
				this.domainCheckState = 'pending';
			}
		})
	}

	public applyUrl() {
		//@TODO temp fix until IMC-497 is implemented.
		if (this.state === 'submitted') {
			return;
		}

		this.state = 'submitted';
		this.projectService.reloadProject().subscribe(() => {
			this.setStatus(this.projectService.environment);
			const fields = this.projectService.environment.fields;

			console.log(fields);
			console.log(fields.domain_info?.state);
			let updateARecord: any = of([]);
			// If the domain can be managed, but the dns is not set yet. Set it now.
			if (fields.domain_info?.state === 'ready') {
				if (
					fields.domain_info?.dns_management_enabled &&
					!fields.domain.includes('inmotionhosting.com')
				) {
					updateARecord = this.updateARecord(
						fields.domain_info,
						fields.machine_details.machine_ip
					);
					console.log(updateARecord);
				}
			}

			updateARecord
				.pipe(
					mergeMap(() =>
						this.apiService
							.post(
								`/v1/environments/${this.projectService.environment.id}/domain`,
								{
									applySsl: fields.domain_info.domain.fqdn === fields.hostname
								}
							)
							.pipe(
								tap((response) => {
									this.ansibleService.playbookStatus.next(
										'playbook-started'
									);
									this.domainCheckState = 'pending';
									this.appService.showConfirmation('Your Site URL is being rolled out to your server!');
								}),
							)
					)
				)
				.subscribe({
					complete: () => {},
					error: () => {
						this.state = 'pending';
					},
				});
		});
	}

	public forceContinue() {
		this.forceContinueState = 'submitted';

		const domainInfo =
			this.projectService.environment.fields.domain_info || {};
		domainInfo.force_continue = true;
		domainInfo.state = 'ready';

		this.projectService
			.updateEnvironmentField({ domain_info: domainInfo })
			.subscribe({
				next: (environment) => {
					this.domainState = environment?.fields?.domain_info?.state;
					this.dnsView = 'state-based';
				},
				error: (response) => {
					this.forceContinueState = 'failed';

					console.error(response);
				},
			});
	}

	public updateARecord(domainInfo, ipAddress) {
		const domainId = domainInfo?.domain.id;
		this.failedDns = false;

		// Fetch the current DNS for a domain.
		return this.getDns(domainId)
			.pipe(this.replaceARecord(domainInfo, ipAddress))
			.pipe(mergeMap((dnsZone) => this.patchDns(domainId, dnsZone)))
			.pipe(this.setFailedState());
	}

	protected patchDns(domainId, dnsZone) {
		const url = this.apiService.formatter.getUrl(
			`/v1/domain-service/dns-zone`
		);
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});
		return this.apiService.http.patch(
			url,
			{ domainName: domainId, zoneData: dnsZone },
			{ headers }
		);
	}

	protected replaceARecord(domainInfo, ipAddress: string) {
		return map((dnsZone: any) => {
			dnsZone = dnsZone.filter((entry) => {
				const domainPrefix = entry.name === '@' ? '' : entry.name + '.';
				return (
					entry.type !== 'A' ||
					domainPrefix + domainInfo.domain.baseDomain !==
						domainInfo.domain.fqdn
				);
			});

			const fqdn = domainInfo.domain.fqdn;

			dnsZone.push({
				name: fqdn.startsWith('www.') ? fqdn.substring(4) : fqdn,
				type: 'A',
				rdata: { aValue: ipAddress },
				ttl: 14400,
			});

			if (!domainInfo.domain.subDomain) {
				dnsZone.push({
					name: 'www.' + fqdn,
					type: 'CNAME',
					rdata: { cnameValue: fqdn },
					ttl: 14400,
				});
			} else if (domainInfo.domain.subDomain === 'www') {
				dnsZone.push({
					name: fqdn,
					type: 'CNAME',
					rdata: { cnameValue: fqdn.substring(4) },
					ttl: 14400,
				});
			}

			new Formatter().formatRecordsForApi(
				dnsZone,
				domainInfo.domain.baseDomain
			);

			return dnsZone;
		});
	}

	protected setFailedState() {
		const url = this.apiService.formatter.getUrl(
			`/v1/environments/${this.projectService.environment.id}`
		);
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});

		return catchError(() => {
			this.projectService.environment.fields.domain_info.state =
				'needs-dns';

			return this.apiService.http
				.patch(
					url,
					{
						fields: {
							domain_info:
								this.projectService.environment.fields
									.domain_info,
						},
					},
					{ headers }
				)
				.pipe(
					map((environment) => {
						this.failedDns = true;
						console.warn('Failed to update DNS');
					})
				);
		});
	}

	protected getDns(domainId) {
		const url = this.apiService.formatter.getUrl(
			'/v1/domain-service/dns-zone/' + domainId
		);
		const headers = this.apiService.getHeaders({
			contentType: 'application/json',
		});

		return this.apiService.http.get(url, { headers });
	}
}
