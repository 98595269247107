<div [class.amp-heading]="this.actions === 'amp'" [class.primary-heading]="this.actions !== 'amp'"
	[class.playground]="this.service && this.service.environment && this.service.environment.fields.cloud_id && (this.location.path().match('overview') || this.location.path().match('setup'))"
	[class]="this.actions === 'amp' ? 'ctw-py-4 ctw-mb-8' : ''" [class.notice-active]="this.notice || this.$notice">
	<div class="ctw-grid ctw-grid-cols-2 ctw-mx-auto ctw-max-w-{{ this.breakpoint }}">
		<div [class]="this.actions === 'amp' ? 'ctw-ml-6 ctw-relative' : ''">
			<h2 *ngIf="this.actions !== 'amp'" class="ctw-m-0 ctw-flex" [class.ctw-capitalize]="this.actions !== 'registrar' && !this.actions.match('dns') && this.actions !== 'project' && this.actions !== 'environment' && this.actions !==
				'hosting'" [class.notranslate]="this.actions === 'project' || this.actions === 'environment' || this.actions ===
				'hosting'">

				<central-team-selector class="team-selector ctw-inline-block ctw-relative ctw-z-10"
					*ngIf="this.allowTeamSwitching" (teamChange)="this.teamChanged($event)"></central-team-selector>

				<mat-icon *ngIf="this.titleIcon !== 'wordpress'" class="ctw-absolute ctw-mt-5">
					{{ this.titleIcon }}
				</mat-icon>

				<svg *ngIf="this.titleIcon === 'wordpress'" fill="#ffffff" version="1.1" id="Capa_1"
					xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32px"
					height="32px" viewBox="0 0 96.24 96.24" xml:space="preserve" stroke="#ffffff"
					class="ctw-relative ctw-mr-2 wordpress-icon">
					<g id="SVGRepo_bgCarrier" stroke-width="0" />
					<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
					<g id="SVGRepo_iconCarrier">
						<g>
							<path
								d="M48.122,0C21.587,0,0.001,21.585,0.001,48.118c0,26.535,21.587,48.122,48.12,48.122c26.532,0,48.117-21.587,48.117-48.122 C96.239,21.586,74.654,0,48.122,0z M4.857,48.118c0-6.271,1.345-12.227,3.746-17.606l20.638,56.544 C14.81,80.042,4.857,65.243,4.857,48.118z M48.122,91.385c-4.247,0-8.346-0.623-12.222-1.763L48.88,51.903l13.301,36.433 c0.086,0.215,0.191,0.411,0.308,0.596C57.992,90.514,53.16,91.385,48.122,91.385z M54.083,27.834 c2.604-0.137,4.953-0.412,4.953-0.412c2.33-0.276,2.057-3.701-0.277-3.564c0,0-7.007,0.549-11.532,0.549 c-4.25,0-11.396-0.549-11.396-0.549c-2.332-0.137-2.604,3.427-0.273,3.564c0,0,2.208,0.275,4.537,0.412l6.74,18.469l-9.468,28.395 L21.615,27.835c2.608-0.136,4.952-0.412,4.952-0.412c2.33-0.275,2.055-3.702-0.278-3.562c0,0-7.004,0.549-11.53,0.549 c-0.813,0-1.77-0.021-2.784-0.052C19.709,12.611,33.008,4.856,48.122,4.856c11.265,0,21.519,4.306,29.215,11.357 c-0.187-0.01-0.368-0.035-0.562-0.035c-4.248,0-7.264,3.702-7.264,7.679c0,3.564,2.055,6.582,4.248,10.146 c1.647,2.882,3.567,6.585,3.567,11.932c0,3.704-1.422,8-3.293,13.986l-4.315,14.421L54.083,27.834z M69.871,85.516l13.215-38.208 c2.471-6.171,3.29-11.106,3.29-15.497c0-1.591-0.104-3.07-0.292-4.449c3.38,6.163,5.303,13.236,5.301,20.758 C91.384,64.08,82.732,78.016,69.871,85.516z" />
						</g>
					</g>
				</svg>

				<span *ngIf="this.actions === 'environment' || this.actions === 'project'"
					class="title ctw-pl-1 ctw-cursor-pointer" [class.ctw-ml-8]="this.allowTeamSwitching"
					[class.ctw-ml-10]="this.titleIcon && this.titleIcon !== 'wordpress'"
					(mouseover)="mouseOveredEdit=true" (mouseout)="mouseOveredEdit=false"
					(click)="this.action.emit('edit-name')">
					{{ this.title }}
				</span>

				<span *ngIf="this.actions !== 'environment' && this.actions !== 'project'"
					class="ctw-pl-1 ctw-overflow-hidden ctw-text-ellipsis ctw-whitespace-nowrap"
					[matTooltip]="this.title"
					[class.ctw-ml-8]="this.allowTeamSwitching"
					[class.ctw-ml-10]="this.titleIcon && this.titleIcon !== 'wordpress'">
					{{ this.title }}
				</span>

				<span *ngIf="this.getTag()"
					class="ctw-ml-2 ctw-px-2 ctw-text-black ctw-text-sm ctw-bg-white mat-stroked-button"
					[class.notranslate]="this.tag === 'team'">
					<mat-icon *ngIf="this.tagIcon" class="ctw-absolute">
						{{ this.tagIcon }}
					</mat-icon>
					<span class="ctw-ml-8">{{ this.getTag() }}</span>
				</span>

				<span class="ctw-text-sm ctw-mt-5 ctw-ml-2">
					{{ this.subTitle }}
				</span>

				<span *ngIf="this.actions === 'environment' || this.actions === 'project'"
					class="editable ctw-cursor-pointer notranslate" (click)="this.action.emit('edit-name')"
					[class.ctw-opacity-50]="!mouseOveredEdit" [class.ctw-opacity-100]="mouseOveredEdit"
					(mouseover)="mouseOveredEdit=true" (mouseout)="mouseOveredEdit=false">
					<mat-icon class="ctw-text-2xl ctw-ml-2">edit</mat-icon>
				</span>
			</h2>

			<h2 *ngIf="this.actions === 'amp'" class="ctw-m-0">
				<a href="https://www.inmotionhosting.com"><img class="amp-brand-logo"
						src="assets/svg/imh-logo.svg" /></a>
			</h2>
		</div>

		<!-- Actions -->
		<div class="actions animate__animated animate__fadeIn ctw-text-right"
			*ngIf="('loading' !== this?.status && 'pending' !== this?.status && this.actions && !this.isSwitchingTeams) || this.actions === 'support' || this.notice === 'playbook-running'">

			<!-- Agent -->
			<ng-container *ngIf="this.actions === 'agent'">
				<button *ngIf="this.menu !== 'user' && 0" mat-raised-button color="accent" class=""
					(click)="this.action.emit('refresh')">
					<mat-icon>refresh</mat-icon> Reset
				</button>
				<ng-container *ngIf="this.menu === 'user'">
					<button mat-flat-button routerLink=".." class="back-to">
						<mat-icon>arrow_back_ios</mat-icon>Back To Search
					</button>
					<button mat-stroked-button [matMenuTriggerFor]="userOptions" class="ctw-border-none actions"
						*ngIf="this.actionsEnabled">
						<mat-icon>settings</mat-icon>
					</button>
					<mat-menu #userOptions="matMenu" xPosition="after">
						<button mat-menu-item (click)="this.action.emit('loginAsUser')">
							<mat-icon>login</mat-icon>
							<span class="notranslate">Log In As User</span>
						</button>
						<button mat-menu-item (click)="this.action.emit('tempAuth')">
							<mat-icon>lock_clock</mat-icon>
							<span class="notranslate">Temporary Auth</span>
						</button>
						<ng-container *ngIf="this.data">
							<central-beta-tester [accountId]="this.data.account_access[0].account_id"
								[isMenuItem]="true" (updated)="this.action.emit('refresh')"></central-beta-tester>
							<mat-divider></mat-divider>
							<central-suspension [accountId]="this.data.account_access[0].account_id" [isMenuItem]="true"
								(updated)="this.action.emit('refresh')"></central-suspension>
						</ng-container>
					</mat-menu>
				</ng-container>
			</ng-container>


			<!-- User -->
			<ng-container *ngIf="this.actions === 'user'">
				<button mat-raised-button routerLink="/logout">
					<mat-icon>logout</mat-icon> Sign Out
				</button>
			</ng-container>

			<!-- Subscriptions -->
			<ng-container *ngIf="this.actions === 'subscriptions'">
				<button *ngIf="this.actionsEnabled" mat-stroked-button
					routerLink="{{this.formatLink('subscriptions', 'receipts')}}" mat-raised-button color="accent"
					class="">
					<mat-icon class="ctw-mr-1">receipt_long</mat-icon>
					<span> Transaction History</span>
				</button>
				<!--
				<button mat-stroked-button [matMenuTriggerFor]="userOptions" class="ctw-ml-3 ctw-border-none actions">
					<mat-icon>settings</mat-icon>
				</button>
				<mat-menu #userOptions="matMenu" xPosition="before">
					<button mat-menu-item routerLink="{{this.formatLink('subscriptions', 'settings')}}">
						<mat-icon>credit_card</mat-icon>
						<span>Payment Method</span>
					</button>
					<button mat-menu-item routerLink="{{this.formatLink('subscriptions', 'receipts')}}">
						<mat-icon>receipt_long</mat-icon>
						<span>Transaction History</span>
					</button>
				</mat-menu>
				-->
			</ng-container>

			<!-- Invoices -->
			<ng-container *ngIf="this.actions === 'invoices'">
				<button mat-flat-button routerLink="{{this.formatLink('receipts', 'subscriptions')}}" class="back-to">
					<mat-icon>arrow_back_ios</mat-icon>Back To Subscriptions
				</button>
			</ng-container>
			<ng-container *ngIf="this.actions === 'invoice'">
				<button mat-flat-button routerLink="{{this.formatLink('receipts.*', 'receipts')}}" class="back-to">
					<mat-icon>arrow_back_ios</mat-icon>Back To Transactions
				</button>
			</ng-container>

			<!-- Support -->
			<ng-container *ngIf="this.actions === 'support'">
				<button mat-raised-button color="accent" class="" (click)="this.action.emit('refresh')">
					<mat-icon>refresh</mat-icon> Refresh
				</button>
				<button mat-stroked-button [matMenuTriggerFor]="userOptions" class="ctw-ml-3 ctw-border-none actions">
					<mat-icon>settings</mat-icon>
				</button>
				<mat-menu #userOptions="matMenu" xPosition="before">
					<a mat-menu-item
						href="https://www.inmotionhosting.com/support/product-guides/wordpress-hosting/central/"
						target="_blank">
						<mat-icon>menu_book</mat-icon> Documentation <mat-icon
							class="ctw-text-base ctw-float-right ctw-text-right ctw-ml-1 ctw-mt-4 ctw-mr-0">open_in_new</mat-icon>
					</a>
					<button mat-menu-item (click)="this.action.emit('ticket')">
						<mat-icon>text_snippet</mat-icon> Open Ticket
					</button>
					<button mat-menu-item *ngIf="this.chatEnabled" (click)="this.action.emit('chat')">
						<mat-icon>question_answer</mat-icon> Live Chat
					</button>
					<button mat-menu-item *ngIf="!this.chatEnabled" [disabled]>
						<mat-icon>question_answer</mat-icon> Live Chat: Offline
					</button>
					<mat-divider></mat-divider>
					<a mat-menu-item href="https://status.inmotioncentral.com/" target="_blank">
						<mat-icon class="ctw-text-red-700">vitals</mat-icon> <span class="ctw-text-red-700">System Status</span> <mat-icon
							class="ctw-text-base ctw-float-right ctw-text-right ctw-ml-1 ctw-mt-4 ctw-mr-0">open_in_new</mat-icon>
					</a>
				</mat-menu>
			</ng-container>
			<ng-container *ngIf="this.actions === 'ticket'">
				<button mat-flat-button routerLink="{{this.formatLink('requests.*', 'requests')}}" class="back-to">
					<mat-icon>arrow_back_ios</mat-icon>Back To Support Requests
				</button>
			</ng-container>
			<ng-container *ngIf="this.actions === 'membership'">
				<button (click)="this.action.emit('invite')" mat-raised-button color="accent" class="ctw-ml-3">
					<mat-icon>group_add</mat-icon> Add Team Members
				</button>
			</ng-container>
			<ng-container *ngIf="this.actions.match('team')">
				<button mat-raised-button color="accent" class="" (click)="this.action.emit('create')">
					<mat-icon>add</mat-icon> Create Team
				</button>
				<button mat-stroked-button [matMenuTriggerFor]="userOptions" class="ctw-ml-3 ctw-border-none actions"
					*ngIf="this.actions !== 'team'">
					<mat-icon>settings</mat-icon>
				</button>
				<mat-menu #userOptions="matMenu" xPosition="before">
					<button mat-menu-item (click)="this.action.emit('toggleArchived')">
						<mat-icon>inventory_2</mat-icon>
						<span class="notranslate">{{ this.actions === 'team-' ? 'Show' : 'Hide' }} Archived</span>
					</button>
				</mat-menu>
			</ng-container>
			<ng-container *ngIf="this.actions === 'email'">
				<button mat-raised-button color="accent" class="" routerLink="/email/new">
					<mat-icon>add</mat-icon> New Subscription
				</button>
			</ng-container>
			<ng-container *ngIf="this.actions === 'domainsearch'">
				<button mat-raised-button color="accent" class="" routerLink="./manage">
					Domain Management
				</button>
			</ng-container>
			<ng-container *ngIf="this.actions === 'domains'">
				<button mat-raised-button color="accent" class="" routerLink="..">
					<mat-icon>search</mat-icon> Find A New Domain
				</button>
				<button mat-stroked-button [matMenuTriggerFor]="userOptions" class="ctw-ml-3 ctw-border-none actions"
					*ngIf="0">
					<mat-icon>settings</mat-icon>
				</button>
				<mat-menu #userOptions="matMenu" xPosition="before">
					<button mat-menu-item (click)="this.action.emit('add')">
						<mat-icon>dns</mat-icon> Add DNS Zone
					</button>
				</mat-menu>
			</ng-container>
			<ng-container *ngIf="this.actions.match('dns')">
				<button mat-flat-button routerLink="/domains/manage" class="back-to">
					<mat-icon>arrow_back_ios</mat-icon>Back To Domain Management
				</button>
				<button mat-raised-button color="accent" (click)="this.action.emit('add')"
					[disabled]="this.actions === 'nodns'" class="ctw-ml-3">
					<mat-icon>add</mat-icon> Add Record
				</button>
			</ng-container>
			<ng-container *ngIf="this.actions === 'registrar'">
				<button mat-flat-button routerLink="/domains/manage" class="back-to">
					<mat-icon>arrow_back_ios</mat-icon>Back To Domain Management
				</button>
				<!--
				<button mat-stroked-button class="ctw-ml-3 ctw-border-current" routerLink="./dns-manager">
					<mat-icon>public</mat-icon>
					<span>Manage DNS Records</span>
				</button>-->
				<!--
				<button mat-stroked-button [matMenuTriggerFor]="userOptions" class="ctw-ml-3 ctw-border-none actions">
					<mat-icon>settings</mat-icon>
				</button>
				<mat-menu #userOptions="matMenu" xPosition="before">
					<button mat-menu-item routerLink="./dns-manager">
						<mat-icon>public</mat-icon>
						<span class="notranslate">Manage DNS Records</span>
					</button>
				</mat-menu>
				-->
			</ng-container>

			<ng-container *ngIf="this.actions.match('projects')">
				<button mat-raised-button color="accent" class="" (click)="this.action.emit('add')">
					<mat-icon>add</mat-icon> New Project
				</button>
				<button mat-stroked-button (click)="this.action.emit('view')" class="ctw-ml-3 ctw-border-none">
					<mat-icon>{{ this.actions !== 'projects' ? 'dashboard' : 'list' }}</mat-icon>
					<span class="ctw-ml-2">{{this.actions !== 'projects' ? 'Grid' : 'List'}} View</span>
				</button>
				<!--
				<button mat-stroked-button [matMenuTriggerFor]="userOptions" class="ctw-ml-3 ctw-border-none actions">
					<mat-icon>settings</mat-icon>
				</button>
				<mat-menu #userOptions="matMenu" xPosition="before">
					<button mat-menu-item (click)="this.action.emit('view')">
						<mat-icon>{{ this.actions !== 'projects' ? 'dashboard' : 'list' }}</mat-icon>
						<span>{{this.actions !== 'projects' ? 'Grid' : 'List'}} View</span>
					</button>
				</mat-menu>
				-->
			</ng-container>


			<ng-container *ngIf="this.actions === 'project'">
				<button mat-flat-button routerLink="/projects/" class="back-to">
					<mat-icon>arrow_back_ios</mat-icon>Back To Projects
				</button>
				<button mat-stroked-button [matMenuTriggerFor]="userOptions" class="ctw-ml-3 ctw-border-none actions">
					<mat-icon>settings</mat-icon>
				</button>
				<mat-menu #userOptions="matMenu" xPosition="before">
					<button mat-menu-item (click)="this.action.emit('edit')">
						<mat-icon>description</mat-icon>
						<span>Project Details</span>
					</button>
					<button mat-menu-item (click)="this.action.emit('invite')">
						<mat-icon>group_add</mat-icon>
						<span>Add Team Members</span>
					</button>
					<button *ngIf="this.data" mat-menu-item
						routerLink="/account/{{this.service.project?.fields?.organization_id}}/subscriptions">
						<mat-icon>paid</mat-icon>
						<span>Manage Subscription</span>
					</button>
					<button mat-menu-item (click)="this.action.emit('key')"
						*ngIf="this.service.getDefaultEnvironment(this.service?.project)?.id">
						<mat-icon>key</mat-icon>
						<span>Recover Connect Key</span>
					</button>
					<mat-divider></mat-divider>
					<button mat-menu-item (click)=" this.action.emit('delete')">
						<mat-icon class="ctw-text-red-700">delete_forever</mat-icon>
						<span class="ctw-text-red-700">Delete Project</span>
					</button>
					<ng-container
						*ngIf="this.authService.isImpersonating() && this.service && this.service.getVpsEnvironment(this.service?.project)?.id">
						<mat-divider></mat-divider>
						<button mat-menu-item
							routerLink="./agent"
                            [queryParams]="{environment_id: this.service.getVpsEnvironment(this.service?.project)?.id}"
							class="ctw-bg-black">
							<mat-icon class="ctw-text-red-700">admin_panel_settings</mat-icon>
							<span style="color:white">Agent Controls</span>
						</button>
					</ng-container>
				</mat-menu>
			</ng-container>


			<ng-container *ngIf="this.actions === 'environment'">
				<button mat-flat-button
					routerLink="/projects/{{ this.service.project.id }}{{this.service.environment.fields.environment_usage === 'unknown' && this.location.path().includes('/configure') ? '/overview' : ''}}"
					class="back-to">
					<mat-icon>arrow_back_ios</mat-icon>Back To {{this.service.environment.fields.environment_usage ===
					'unknown' && this.location.path().includes('/configure') ? 'Setup' : 'Dashboard'}}
				</button>
				<button *ngIf="this.actionsEnabled && this.service?.client && 0" mat-raised-button
					color="{{ this.service?.client ? 'primary': ''}}"
					[disabled]="(!this.service?.client || this.service.environment.fields.environment_usage === 'unknown') && !this.authService.isImpersonating()"
					(click)="this.action.emit('login')">

					<span class="ctw-flex">
						<svg *ngIf="(this.service?.client && this.service.environment.fields.environment_usage !== 'unknown') || this.authService.isImpersonating()"
							fill="#ffffff" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink" width="20px" height="24px"
							viewBox="0 0 96.24 96.24" xml:space="preserve" stroke="#ffffff"
							class="ctw-relative ctw-mr-2 wordpress-icon">
							<g id="SVGRepo_bgCarrier" stroke-width="0" />
							<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
							<g id="SVGRepo_iconCarrier">
								<g>
									<path
										d="M48.122,0C21.587,0,0.001,21.585,0.001,48.118c0,26.535,21.587,48.122,48.12,48.122c26.532,0,48.117-21.587,48.117-48.122 C96.239,21.586,74.654,0,48.122,0z M4.857,48.118c0-6.271,1.345-12.227,3.746-17.606l20.638,56.544 C14.81,80.042,4.857,65.243,4.857,48.118z M48.122,91.385c-4.247,0-8.346-0.623-12.222-1.763L48.88,51.903l13.301,36.433 c0.086,0.215,0.191,0.411,0.308,0.596C57.992,90.514,53.16,91.385,48.122,91.385z M54.083,27.834 c2.604-0.137,4.953-0.412,4.953-0.412c2.33-0.276,2.057-3.701-0.277-3.564c0,0-7.007,0.549-11.532,0.549 c-4.25,0-11.396-0.549-11.396-0.549c-2.332-0.137-2.604,3.427-0.273,3.564c0,0,2.208,0.275,4.537,0.412l6.74,18.469l-9.468,28.395 L21.615,27.835c2.608-0.136,4.952-0.412,4.952-0.412c2.33-0.275,2.055-3.702-0.278-3.562c0,0-7.004,0.549-11.53,0.549 c-0.813,0-1.77-0.021-2.784-0.052C19.709,12.611,33.008,4.856,48.122,4.856c11.265,0,21.519,4.306,29.215,11.357 c-0.187-0.01-0.368-0.035-0.562-0.035c-4.248,0-7.264,3.702-7.264,7.679c0,3.564,2.055,6.582,4.248,10.146 c1.647,2.882,3.567,6.585,3.567,11.932c0,3.704-1.422,8-3.293,13.986l-4.315,14.421L54.083,27.834z M69.871,85.516l13.215-38.208 c2.471-6.171,3.29-11.106,3.29-15.497c0-1.591-0.104-3.07-0.292-4.449c3.38,6.163,5.303,13.236,5.301,20.758 C91.384,64.08,82.732,78.016,69.871,85.516z" />
								</g>
							</g>
						</svg> {{ this.service?.client ?
						'Admin' :
						this.service.wpRestService.sitesUnavailable.includes(this.service.environment?.fields?.connection_url)
						? 'Unable To Connect' : 'Connecting...' }}
					</span>
				</button>
				<button mat-stroked-button [matMenuTriggerFor]="userOptions" class="ctw-ml-3 ctw-border-none actions"
					[disabled]="(this.service.environment?.fields?.environment_usage === 'unknown' && !this.authService.isImpersonating())">
					<mat-icon>settings</mat-icon>
				</button>
				<mat-menu #userOptions="matMenu" xPosition="before">
					<ng-container *ngIf="this.actionsEnabled">
						<bgc-app-access></bgc-app-access>
						<button mat-menu-item (click)="this.action.emit('goaccess')"
							*ngIf="this.service.environment?.fields?.machine_details">
							<mat-icon>query_stats</mat-icon>
							<span>Access Logs <mat-icon
									class="ctw-text-base ctw-float-right ctw-text-right ctw-ml-1 ctw-mt-4 ctw-mr-0">open_in_new</mat-icon></span>
						</button>
						<button mat-menu-item (click)="this.action.emit('files')">
							<mat-icon>folder_open</mat-icon>
							<span>File Manager <mat-icon
									class="ctw-text-base ctw-float-right ctw-text-right ctw-ml-1 ctw-mt-4 ctw-mr-0">open_in_new</mat-icon></span>
						</button>
						<button mat-menu-item (click)="this.action.emit('add-ssh-key')">
							<mat-icon>terminal</mat-icon>
							<span>SSH Access</span>
						</button>
						<button mat-menu-item (click)="this.action.emit('access-sftp')"
							*ngIf="this.service.environment?.fields?.machine_details">
							<mat-icon>password</mat-icon>
							<span>SFTP Access</span>
						</button>
						<button mat-menu-item (click)="this.action.emit('restart-server')"
							*ngIf="this.service.environment?.fields?.machine_details && 0">
							<mat-icon>restart_alt</mat-icon>
							<span>Restart Server</span>
						</button>
						<mat-divider *ngIf="this.service.environment?.fields?.machine_details && 0"></mat-divider>
						<button mat-menu-item (click)="this.action.emit('power-off')"
							*ngIf="this.service.environment?.fields?.machine_details && 0">
							<mat-icon class="ctw-text-red-700">power_off</mat-icon>
							<span class="ctw-text-red-700">Stop Server</span>
						</button>
						<button mat-menu-item (click)="this.action.emit('reset-server')"
							*ngIf="this.service.environment?.fields?.machine_details && this.configService.hasFeature('serverReset')"
							class="danger">
							<mat-icon class="ctw-text-red-700">360</mat-icon>
							<span class="ctw-text-red-700">Start Over</span>
						</button>
						<mat-divider *ngIf="this.service.environment?.fields?.machine_details"></mat-divider>
					</ng-container>
					<ng-container
						*ngIf="this.authService.isImpersonating() && this.service.environment?.fields?.playbook_running === 'in-progress'">
						<button mat-menu-item (click)="this.action.emit('cancel-playbook')" class="ctw-bg-black"
							*ngIf="this.service.environment?.fields?.machine_details">
							<mat-icon class="ctw-text-red-700">cancel</mat-icon>
							<span style="color:white">Cancel Updates</span>
						</button>
					</ng-container>
				</mat-menu>
			</ng-container>
			<ng-container *ngIf="this.actions === 'hosting'">
				<button *ngIf="this.service" mat-flat-button routerLink="/projects/{{ this.service?.project?.id }}"
					class="back-to">
					<mat-icon>arrow_back_ios</mat-icon>Back To Dashboard
				</button>
				<button mat-raised-button color="accent" class="" (click)="this.action.emit('chat')">
					<mat-icon>support_agent</mat-icon> Speak With Sales
				</button>
			</ng-container>

			<ng-container *ngIf="this.actions === 'checkout'">
				<button mat-flat-button routerLink="../../" class="back-to">
					<mat-icon>arrow_back_ios</mat-icon>Back To Project
				</button>
			</ng-container>

			<!-- Cart -->
			<ng-container *ngIf="this.cart">
				<button mat-stroked-button [matMenuTriggerFor]="shoppingCart" #shoppingCartTrigger="matMenuTrigger"
					matBadge="{{ this.cart.length || null }}" class="ctw-ml-3 ctw-border-none cart-trigger"
					matBadgePosition="after" [disabled]="checkout">
					<mat-icon>shopping_cart</mat-icon>
				</button>
				<mat-menu #shoppingCart="matMenu" xPosition="before">
					<button mat-menu-item class="ctw-bg-gray-200 ctw-cursor-default">
						<strong>Pending Registration</strong>
					</button>
					<mat-divider class="ctw-mb-4"></mat-divider>
					<ng-container *ngFor="let item of this.cart; index as i">
						<button mat-menu-item
							class="ctw-flex ctw-justify-between ctw-p-0 ctw-px-4 cart ctw-cursor-default">
							<span>
								<mat-icon (click)="this.action.emit({ remove: i, event: $event })"
									class="remove-item ctw-text-red-700 ctw-mr-3 ctw-cursor-pointer">
									close
								</mat-icon>
								<span class="notranslate notransform ctw-pr-12">{{item.name}}</span>
							</span>
							<span class="notranslate ctw-flex-1 ctw-text-right">{{moneyFormat(item.price)}}</span>
						</button>
					</ng-container>
					<div *ngIf="!this.cart.length" class="ctw-pb-4 ctw-text-center ctw-text-imh-gray-200 ctw-px-8">No
						domains selected!</div>
					<mat-divider class="ctw-mx-4"></mat-divider>
					<div class="ctw-flex ctw-justify-between ctw-mb-4 ctw-py-2 ctw-justify-between ctw-px-4"
						[class.ctw-text-imh-gray-200]="!this.cart.length">
						<span>SubTotal</span><span
							class="notranslate ctw-font-bold">{{moneyFormat(this.cartTotal)}}</span>
					</div>
					<div class="ctw-p-4">
						<button mat-raised-button (click)="this.action.emit('checkout')" color="primary"
							class="ctw-text-center ctw-w-full" [disabled]="!this.cart.length">
							<mat-icon class="ctw-relative ctw-mr-2 ctw-mb-1">payment</mat-icon> Purchase
						</button>
					</div>
				</mat-menu>
			</ng-container>
			<ng-container *ngIf="this.actions === 'amp'">
				<div class="ctw-flex ctw-flex-col ctw-justify-center ctw-h-full ctw-ml-3 amp-chat-call-container">
					<div class="ctw-flex">
						<div class="ctw-flex ctw-cursor-pointer ctw-ml-3 " (click)="this.action.emit('openchat')">
							<mat-icon style="color:#c52228;">sms</mat-icon>
							<span class="ctw-ml-3 ctw-text-lg amp-call">Chat Now</span>
						</div>
						<div class="ctw-flex ctw-cursor-pointer ctw-ml-3 " (click)="this.openAmpCall(phoneNumber)">
							<mat-icon style="color:#c52228;">call</mat-icon>
							<span class="ctw-ml-3 ctw-text-lg amp-call">{{phoneNumber}}</span>
						</div>
						<central-locale-settings class="amp-cls transparent ctw-ml-3"
							mode="language"></central-locale-settings>
					</div>
				</div>
			</ng-container>
		</div>
	</div>

	<div *ngIf="this.notice" class="notice {{this.noticeBgClass}}" [class.home]="this.allowTeamSwitching">
		<div class="ctw-flex ctw-justify-center ctw-mx-auto ctw-max-w-{{ this.breakpoint }} {{this.noticeTextClass}}">
			<ng-container *ngIf="this.notice === 'playbook-running'">
				<mat-icon class="ctw-mr-1">update</mat-icon>
				<span class="ctw-uppercase ctw-font-bold">Configuration Change in
					progress</span>
				<a *ngIf="this.noticeExpanded" class="ctw-ml-6 ctw-text-xs" (click)="this.openNotice()">Learn More</a>
			</ng-container>
			<ng-container *ngIf="this.notice !== 'playbook-running'">
				<mat-icon *ngIf="this.noticeIcon" class="ctw-mr-3">{{this.noticeIcon}}</mat-icon> <span
					[innerHTML]="this.notice"></span>
			</ng-container>
		</div>
	</div>

	<div *ngIf="this.$notice && !this.notice" class="notice {{this.$notice.acf.banner_bg_class}}"
		[class.home]="this.allowTeamSwitching">
		<div
			class="ctw-flex ctw-justify-center ctw-mx-auto ctw-max-w-{{ this.breakpoint }} {{this.$notice.acf.banner_text_class}}">
			<mat-icon *ngIf="this.$notice.acf.banner_icon" class="ctw-mr-3">{{this.$notice.acf.banner_icon}}</mat-icon>
			<span [innerHTML]="this.$notice.acf.banner_text"></span>
			<a class="ctw-ml-6 ctw-text-xs" (click)="this.openNotice()">Learn More</a>
		</div>
	</div>

	<mat-progress-bar [class.home]="this.allowTeamSwitching" [class.notice-active]="this.notice || this.$notice"
		*ngIf="'loading' === this?.status || 'pending' === this?.status || 'blocked' === this?.status || 'submitted' === this?.status"
		mode="indeterminate" color="primary"></mat-progress-bar>
</div>
<div *ngIf="this.isSwitchingTeams" class="team-switching-overlay">
	<mat-spinner class="component-loader" diameter="50" color="primary"></mat-spinner>
	<div class="ctw-text-center ctw-text-xl">
		Switching Teams<br /><br />
	</div>
</div>
