import { Component, OnDestroy, OnInit} from '@angular/core';
import { ProjectService } from '../../project.service';
import { ProfileService, ConfigurationService } from '@central/ng-shared';

@Component({
	selector: 'central-security',
	templateUrl: './security.component.html',
	styleUrls: ['./security.component.scss'],
})
export class SecurityComponent {
	state = 'success';

	private subscriptions = [];

	constructor(
		public configService: ConfigurationService,
		public projectService: ProjectService,
		private profileService: ProfileService,
	) { }
}
