import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginFormComponent } from './login-form/login-form.component';
import { FirebaseAuthComponent } from './firebase-auth/firebase-auth.component';
import './models';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { ResendConfirmationComponent } from './resend-confirmation/resend-confirmation.component';
import { CoreModule } from '../core/core.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { RegistrationComponent } from './registration/registration.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { GuestGuard, UserGuard, AgentGuard } from './services';
import { AccountSelectorComponent } from './account-selector/account-selector.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { JwtComponent } from './jwt/jwt.component';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { TwoFactorAuthComponent } from './two-factor-auth/two-factor-auth.component';
import { TwoFaSendCodeComponent } from './two-factor-auth/two-fa-send-code/two-fa-send-code.component';
import { EnableTwoFactorComponent } from './two-factor-auth/enable-two-factor/enable-two-factor.component';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { PhoneNumberModule } from '../phone-number';
import { RemoveDeviceComponent } from './two-factor-auth/enable-two-factor/remove-device/remove-device.component';
import { GenericConfirmComponent } from './generic-confirm/generic-confirm.component';
import { SessionService } from './services/session.service';
import { RouterModule } from '@angular/router';

@NgModule({
	declarations: [
		LoginFormComponent,
		LoginModalComponent,
		FirebaseAuthComponent,
		ForgotPasswordComponent,
		AccountSelectorComponent,
		ResendConfirmationComponent,
		ResetPasswordComponent,
		ConfirmationComponent,
		RegistrationComponent,
		UpdatePasswordComponent,
		JwtComponent,
		TwoFactorAuthComponent,
		TwoFaSendCodeComponent,
		EnableTwoFactorComponent,
		RemoveDeviceComponent,
		GenericConfirmComponent,
	],
	imports: [
		CommonModule,
		CoreModule,
		FormsModule,
		RouterModule,
		ReactiveFormsModule,
		PhoneNumberModule,
		// Material.
		MatProgressSpinnerModule,
		MatFormFieldModule,
		MatButtonModule,
		MatRadioModule,
		MatInputModule,
		MatSelectModule,
		MatIconModule,
		MatSnackBarModule,
		MatCheckboxModule,
		MatPasswordStrengthModule,
		MatTableModule,
		MatMenuModule,
		MatDialogModule,
	],
	exports: [
		AccountSelectorComponent,
		UpdatePasswordComponent,
		ForgotPasswordComponent,
		RegistrationComponent,
		LoginFormComponent,
		FirebaseAuthComponent,
		EnableTwoFactorComponent,
	],
	providers: [GuestGuard, AgentGuard, UserGuard, SessionService],
})
export class AuthenticationModule {
	static forRoot(config): ModuleWithProviders<AuthenticationModule> {
		throw new Error('Method Deprecated: Use CoreServicesModule.forRoot() instead.');
	}
}
