<div class="ctw-p-8">
    <div>
        <mat-form-field>
            <mat-label>Order Generated By:</mat-label>
            <mat-select [(ngModel)]="salespersonId" (selectionChange)="this.internalUpdated()">
                <mat-option value="">Select Salesperson</mat-option>
                <mat-option *ngFor="let salesperson of salespersonData" [value]="salesperson.id">{{salesperson.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>2nd Order Generated By:</mat-label>
            <mat-select [(ngModel)]="salespersonId2" (selectionChange)="this.internalUpdated()">
                <mat-option value="">Select Salesperson</mat-option>
                <mat-option *ngFor="let salesperson of salespersonData" [value]="salesperson.id">{{salesperson.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div>
        <mat-label>Account Type:</mat-label>
        <mat-radio-group class="ctw-w-full ctw-flex ctw-flex-col sm:ctw-flex-row ctw-flex-wrap"
            [(ngModel)]="this.customerType" name="customerType" aria-label="Customer Type" (change)="this.internalUpdated()">
            <mat-radio-button class="ctw-mr-5 ctw-mb-3" *ngFor="let ct of ['Live','Test','Employee','Comp']"
                [value]="ct">
                <span>{{ct}}</span>
            </mat-radio-button>
        </mat-radio-group>
    </div>
</div>