import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService, ProfileService } from '@central/ng-shared';
import { ProjectService } from '../../../../project/project.service';

@Component({
	selector: 'central-monarx',
	templateUrl: './monarx.component.html',
	styleUrls: ['./monarx.component.scss'],
})

export class MonarxComponent implements OnInit, OnDestroy {

	public state = 'loading';
	private pollId;

	public monarxInstalled = false;
	public monarxPlugin = {
		status: 'loading',
		url: '',
		token: ''
	};
	public monarxAgentId = '';
	public available = false;

	private subscriptions = [];

	constructor(
		public apiService: ApiService,
		public projectService: ProjectService,
		private profileService: ProfileService,
	) {}

	ngOnInit(): void {
		this.projectService.reloadProject().subscribe(() => {
			if (this.projectService.environment.fields.monarx_status === 'removed') {
				this.updateState();
			} else {
				this.getAgentId();
			}
		});
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe())
		this.stopPoll();
	}

	private startPoll() {
		if (!this.pollId) {
			this.pollId = setInterval(() => { this.getAgentId() }, 10000);
		}
	}

	private stopPoll() {
		clearInterval(this.pollId);
		this.pollId = null;
	}

	public toggleInstall() {
		this.available = false;
		this.state = 'updating';
		if (!this.monarxInstalled) {
			this.apiService
				.post(`/v1/vps/server/install-monarx`,
					{ veid: this.projectService.environment.fields?.machine_details?.veid })
				.subscribe(
					(response) => {
						if (response['status'] === 0) {
							this.state = 'updating';
							this.setField('installing');
							this.startPoll();
						} else {
							this.state = 'failed';
						}
					},
					(error) => {
						this.state = 'failed';
						console.log(error);
					}
				);
		} else {
			this.apiService
				.post(`/v1/vps/server/remove-monarx`,
					{ veid: this.projectService.environment.fields?.machine_details?.veid })
				.subscribe(
					(response) => {
						this.state = 'success';
						this.setField('removed');
					},
					(error) => {
						this.state = 'failed';
						console.log(error);
					}
				);
		}
	}

	public getPluginUrl() {
		this.apiService
			.get(`/v1/monarx/plugin/link`,
				{ resource_id: this.projectService.environment.id })
			.subscribe(
				(response) => {
					this.monarxPlugin.token = response['token'];
					this.monarxPlugin.url = response['url'];
					this.monarxPlugin.status = 'success';
				},
				(error) => {
					this.monarxPlugin.status = 'error';
					console.log(error);
				}
			);
	}

	private setField(value) {
		this.projectService.updateEnvironmentField({
			monarx_status: value,
		}).subscribe((response) => {
			this.monarxInstalled = response.fields.monarx_status === 'installed';
			this.updateState();
		})
	}

	public getAgentId() {
		this.apiService
			.get(`/v1/monarx/agent`,
				{ resource_id: this.projectService.environment.id })
			.subscribe(
				(response) => {
					this.setField('installed');
					this.monarxAgentId = response['agent_id'];
				},
				(error) => {
					if (this.projectService.environment.fields.monarx_status === 'installed') {
						this.monarxPlugin.status = 'error';
						this.available = true;
						this.monarxInstalled = false;
						this.state = 'failed';
						console.log(error);
					} else {
						this.updateState();
					}
				}
			);
	}

	private updateState() {
		const monarxField = this.projectService.environment.fields.monarx_status;
		if (monarxField === 'installing' || monarxField === 'removing') {
			this.monarxInstalled = false;
			this.state = 'updating';
			this.available = false;
			this.startPoll();
		} else if (monarxField === 'installed') {
			this.stopPoll();
			this.monarxInstalled = true;
			this.state = 'success';
			this.available = true;
			this.getPluginUrl();
		} else {
			this.monarxInstalled = false;
			this.state = 'success';
			this.available = true;
		}
	}

}
