import { Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { ConfigurationService } from "@central/ng-shared";
import { AmpOpApiService } from '../amp-op-api/amp-op-api.service';


@Component({
	selector: 'amp-offers',
	templateUrl: 'amp-offers.component.html',
	styleUrls: ['amp-offers.component.scss'],
})
export class AmpOffersComponent implements OnDestroy {
    public loading = false;
    @Input() public upsellAmpOffers: any[] = [];
    @Input() public domainOnly = false;
    @Input() public hasFreeEligibleDomain = false;
    public offers: any[] = [];
    public selectedOffer: any = null;
    public addToCartLoading = false;
    @Output() public upsellAmpOfferAdded = new EventEmitter();
    
	public constructor(
        public configService: ConfigurationService,
		public ampOpApiService: AmpOpApiService,
    ) { }

    public ngOnDestroy() {
        this.selectedOffer = null;
        this.addToCartLoading = false;
    }

    public formatPrice(value: number) { 
        return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    public perMonth(value: number, termLength: number) {
        return `$${this.formatPrice(value / termLength)}/mo`;
    }

    public getTermLength(termType: string, termLength: string) {
        return termType === 'year' 
            ? parseInt(termLength, 10) * 12 
            : parseInt(termLength, 10);    
    }

    public displayTerm(offer) {
        let termType = offer.TermType?.charAt(0).toUpperCase() + offer.TermType?.slice(1)
        if(offer.TermLength > 1) {
            termType += 's';
        }
        return offer.TermLength + ' ' + termType;
    }

    public displayDiscountPrice(offer) {
        const discountPrice = parseFloat(offer.DiscountPrice);
        const termLength = this.getTermLength(offer.TermType, offer.TermLength);

        return `${this.perMonth(discountPrice, termLength)}`;
    }

    public displayProductType(offer) {
        let productType = '';
        if(offer.hasOwnProperty('ProductType')) {
            if(offer.ProductType.includes(':')) {
                const productTypeArray = offer.ProductType.split(':');
                productType = ` - ${productTypeArray[1]} ${productTypeArray[0]}`;
            } else {
                productType = ` - ${offer.ProductType}`;
            }
        }
        return productType;
    }

    public displayRenewalPrice(offer) {
        const discountPrice = parseFloat(offer.DiscountPrice);
        const price = parseFloat(offer.Price);
        const termLength = this.getTermLength(offer.TermType, offer.TermLength);
        
        let result = '';
        if(discountPrice !== price) {
           result += `Renews at ${this.perMonth(price, termLength)}`;
        }
        return result;
    }

    public addToCart(offer) {
        this.selectedOffer = offer;
        this.addToCartLoading = true;
        this.upsellAmpOfferAdded.emit(offer);
    }

    public skipToCart() {
        this.upsellAmpOfferAdded.emit(null);
    }
}
