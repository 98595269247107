<div class="ctw-w-full ctw-flex ctw-justify-center">
	<div class="ctw-w-full sm:ctw-max-w-5xl" *ngIf="loading === false">
        <dash-card class="ctw-mb-5" icon="lists" [showHeader]="false" *ngFor="let offer of this.upsellAmpOffers">
            <div  class="ctw-flex ctw-justify-between ctw-p-5">
                <div class="ctw-flex ctw-flex-col">
                    <div class="ctw-flex">
                        <div class="ctw-text-2xl">{{offer.ProductName}}</div>
                        <div class="ctw-italic ctw-ml-3 ctw-flex ctw-flex-col ctw-justify-center">{{displayProductType(offer)}}</div>
                    </div>
                    <div class="ctw-flex">
                        <div class="ctw-text-lg">{{displayDiscountPrice(offer)}}</div>
                        <div class="ctw-ml-3"> - {{displayTerm(offer)}}</div>
                        <div class="ctw-ml-3"> - {{displayRenewalPrice(offer)}}</div>
                    </div>
                    <div *ngIf="offer.FreeDomain && this.hasFreeEligibleDomain">
                        <div class="ctw-text-sm ctw-text-gray-500">Get your domain for free with this offer</div>
                    </div>
                </div>
                <div class="ctw-flex ctw-flex-col ctw-justify-end">
                    <div class="sm:ctw-ml-3 ctw-mt-3 sm:ctw-mt-0 ctw-w-full sm:ctw-w-min">
                        <central-progress-button type="raised" [state]="addToCartLoading && selectedOffer === offer ? 'submitted' : 'pending'"
                            color="primary" (click)="addToCart(offer)"
                            class="animate__animated animate__fadeIn amp-domain ctw-flex ctw-w-full sm:ctw-w-min">
                            Add To Cart
                        </central-progress-button>
                    </div>
                </div>
            </div>
        </dash-card>
        <div class="ctw-flex ctw-justify-end ctw-mt-4">
            <button class="offer-continue-button" mat-button mat-stroked-button mat-ripple type="button" color="primary"
                (click)="skipToCart()">
                Continue
            </button>
        </div>
    </div>
</div>