declare const paypal;
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApiService } from '@central/ng-shared';
import _debounce from 'lodash/debounce';

@Component({
	selector: 'paypal-button',
	templateUrl: 'paypal-button.component.html',
	styleUrls: ['paypal-button.component.scss'],
})
export class PaypalButtonComponent {
    public payPalScriptUrl: string;
    public payPalUrlSettings: string;
    public payPalClientId: string;
    public payPalLoaded = false;
    public payPalButtonLoading = true;
    public headerOptions = { headers: {
        "Content-Type":'application/json; charset=utf-8'
    }}

    @Input() public createOrderUrl: string;
    @Input() public approveOrderUrl: string;
	@Input() public chargeAmount: string;
	@Input() public subTotal: string;
	@Input() public salesTax: string;
	@Input() public brand: string;
	@Input() public currency: string;
	@Input() public formValid: boolean;
    @Input() public paymentMethod: string;

    public showPaypalButtonDebounce: any;

    @Output() public paypalResult= new EventEmitter();

    public constructor(public apiService: ApiService) {
        this.payPalButtonLoading = true;
        this.showPaypalButtonDebounce = _debounce(() => {
            this.showPaypalButton();
        }, 1000);
    }

	private loadPaypalScript() {
		this.payPalScriptUrl = "https://www.paypal.com/sdk/js?client-id=";
		this.payPalScriptUrl += this.payPalClientId;
        this.payPalScriptUrl += this.payPalUrlSettings;
		
		return new Promise((resolve, reject) => {
		  const scriptElement = document.createElement('script')
		  scriptElement.id = "paypal-script"
		  scriptElement.src = this.payPalScriptUrl
		  scriptElement.onload = resolve
		  document.body.appendChild(scriptElement)
	  })
	}

    public setPaypalStatus(valid) {
        this.resetPaypalButton().then(() => {
            if(valid === true) {
                this.showPaypalButtonDebounce();
            }
        })
	}

	public showPaypalButton() {
        this.payPalButtonLoading = true;
        this.loadPaypalScript().then(() => {
            const vm = this
            this.payPalLoaded = true;
            const paypalContainer = document.getElementById('paypal-checkout-button-container')
            if(paypalContainer) {
                paypalContainer.innerHTML = ""
            }
            paypal.Buttons({
                style: {label: 'checkout'},
                createOrder: (data,actions) => vm.createOrder(data,actions),
                onApprove: (data,actions) => vm.onApprove(data,actions),
                onError: (err) => vm.onError(err)
            }).render('#paypal-checkout-button-container');
            this.payPalButtonLoading = false;
        })
	}

    public resetPaypalButton() {
        return new Promise((resolve, reject) => {
            if(this.payPalLoaded) {
                const paypalScript = document.getElementById('paypal-script')
                if(paypalScript) {
                    paypalScript.remove()	
                }
                this.payPalLoaded = false;
                resolve(null);
            } else {
                resolve(null);
            }
        })
    }

    public setup(data) {
        this.payPalClientId = data.clientId;
        this.payPalUrlSettings = data.settings;
        this.chargeAmount = data.chargeAmount;
        this.subTotal = data.subTotal;
        this.salesTax = data.salesTax;
        this.createOrderUrl = data.createOrderUrl;
        this.approveOrderUrl = data.approveOrderUrl;
        this.brand = data.brand;
        this.currency = data.currency;
        this.payPalButtonLoading = true;
        this.setPaypalStatus(true);
    }

    public createOrder(data, actions) {
        const vm = this;
        return new Promise((resolve, reject) => {
            vm.apiService.http.post(this.createOrderUrl,
                JSON.stringify({
                    Brand: vm.brand,
                    Intent: 'create',
                    PayPal: {
                        intent: 'AUTHORIZE',
                        purchase_units: [{
                            amount: {
                                value: parseFloat(this.chargeAmount).toFixed(2),
                                currency_code: vm.currency,
                                breakdown: {
                                    item_total: {
                                        currency_code: vm.currency,
                                        value: parseFloat(this.subTotal).toFixed(2)
                                    },
                                    tax_total: {
                                        currency_code: vm.currency,
                                        value: parseFloat(this.salesTax).toFixed(2)
                                    }
                                }
                            }
                        }]
                    }
                }),
                this.headerOptions
            ).subscribe(
                (resp) => {
                    resolve(resp['id']);
                },
                (err) => {
                    vm.onError(err)
                    reject(err)
                }
            );
        })
    }

    public onApprove(data, actions) {
        const vm = this;
        if (!location.hostname.match(/^central/)) {
            console.log(JSON.stringify(data));
        }
        return new Promise((resolve, reject) => {
            vm.apiService.http.post(this.approveOrderUrl,
                JSON.stringify({
                    Brand: vm.brand,
                    Intent: 'authorize',
                    PayPal: {
                        Id: data.orderID
                    }
                }),
                vm.headerOptions
            ).subscribe(
                (resp) => {
                    if(resp.hasOwnProperty('purchase_units')) {
                        const result = {
                            PaymentType: 'PayPal',
                            PayPalAuthorized: true,
                            PaymentId: resp['purchase_units'][0].payments.authorizations[0].id,
                        }
                        vm.paypalResult.emit(result);
                        resolve(null);
                    } else {
                        vm.onError(resp)
                        reject('Something went wrong.')
                        
                    }
                    
                },
                (err) => {
                    vm.onError(err)
                    reject(err)
                }
            );
        })
    }

    public onError(err) {
        // Show an error page here, when an error occurs
        if (!location.hostname.match(/^central/)) {
            console.log(err);
        }
        this.paypalResult.emit(err)
    }


}